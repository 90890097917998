<template>
  <Toast />
  <div style="height: 100%">
    <div class="h2 text-center">Entuba de Incidentes</div>

    <div class="flex" style="justify-content: space-between">
      <!--  -->
      <div v-if="pendingEntuba" class="flex" style="gap: 5px; margin: 20px 0px 20px 0px">
        <div>
          <!-- <Button style="background: var(--color_brand_medium); color: white; width:100px" @click="printFullData()"
          label="Debugar"></Button> -->
          <Button v-if="fullData.length > 0" style="
              background: var(--color_brand_medium);
              color: white;
              width: 150px;
            " @click="pasteEntubaTableIncidentBatch" label="Colar do excel"></Button>
          <Button v-if="fullData.length > 0" style="
              background: var(--color_brand_medium);
              color: white;
              width: 150px;
              margin-left: 8px;
            " @click="validate()" :disabled="underValidation" label="Validar"></Button>
          <Button v-if="fullData.length > 0" style="
              background: var(--color_brand_medium);
              color: white;
              width: 150px;
              margin-left: 8px;
            " @click="entuba()" :disabled="!allValidated" label="Entubar"></Button>
          <InputNumber v-if="fullData.length == 0" v-model="tableSize"></InputNumber>
          <Button v-if="fullData.length == 0" style="
              background: var(--color_brand_medium);
              color: white;
              width: 150px;
              margin-left: 8px;
            " @click="mountTable()" label="Criar tabela"></Button>
        </div>
      </div>
      <!--  -->
      <div v-else class="flex" style="gap: 5px; margin: 20px; margin-left: 0px">
        <Button style="width: 100px" label="Limpar" class="p-button p-component p-button-danger" @click="mountTable()" />
      </div>
      <!--  -->
      <div class="flex align-items-center">
        <Button style="
            background: var(--color_brand_medium);
            color: white;
            width: 117px;
            margin-left: 8px;
          " @click="instructionsCheck = true" label="Instruções"></Button>
      </div>
    </div>
    <!--  -->
    <Dialog class="customInstructionsDialogBatchInsert" v-model:visible="instructionsCheck" modal :style="{ width: '50rem' }"
      :dismissable-mask="true">
      <Card>
        <template #title>
          <div class="flex" style="justify-content: space-between">
            <div>
              {{ this.$langGetValue("entubaInstructionHead") }}
            </div>
            <div>
              <i class="pi pi-times" @click="instructionsCheck = false" style="cursor: pointer"></i>
            </div>
          </div>
        </template>

        <template #subtitle>
          <div class="flex flex-column">
            <div class="mt-4" v-html="this.$langGetValue('entubaInstructionHeadSub')"></div>
            <Button class="primaryButton" style="margin-left: 24px; width: 200px" icon="pi pi-download"
              label="Download template" @click="downloadExcelTemplate" />
          </div>
        </template>
      </Card>
    </Dialog>
    <!--  -->
    <Dialog class="customHierarchyDialogBatchInsert" v-model:visible="hierarchyMultipleVisible" modal>
      <div class="h4" style="margin-bottom: 8px; margin-top: 20px">
        {{ this.$langGetValue("area") }}
      </div>
      <div class="flex align-items-center gap-3 mb-3">
        <HierarchyComponent :class="{
          'invalid-input': this.areaHierarchyValue === null && buttonClicked,
        }" :selectedAreas="areaHierarchyValue" mode="entuba" selection="multiple" type="originated"
          @returned-areas="setObuAP"></HierarchyComponent>
      </div>
      <div class="flex justify-content-center" style="gap: 5px">
        <Button style="width: 100px" class="p-button p-component p-button-danger" type="button"
          :label="this.$langGetValue('buttonCancel')" severity="secondary"
          @click="hierarchyMultipleVisible = false"></Button>
        <Button style="
            background: var(--color_brand_medium);
            color: white;
            width: 100px;
          " type="button" :label="this.$langGetValue('buttonSave')" @click="saveHierarchy()"></Button>
      </div>
    </Dialog>
    <Dialog class="customHierarchyDialogBatchInsert" v-model:visible="hierarchySingleVisible" modal>
      <div class="h4" style="margin-bottom: 8px; margin-top: 20px">
        {{ this.$langGetValue("area") }}
      </div>
      <div class="flex align-items-center gap-3 mb-3">
        <HierarchyComponent :class="{
          'invalid-input': this.areaHierarchyValue === null && buttonClicked,
        }" :selectedAreas="areaHierarchyValue" mode="entuba" selection="single" type="originated"
          @returned-areas="setObuAP"></HierarchyComponent>
      </div>
      <div class="flex justify-content-center" style="gap: 5px">
        <Button style="width: 100px" class="p-button p-component p-button-danger" type="button"
          :label="this.$langGetValue('buttonCancel')" severity="secondary"
          @click="hierarchySingleVisible = false"></Button>
        <Button style="
            background: var(--color_brand_medium);
            color: white;
            width: 100px;
          " type="button" :label="this.$langGetValue('buttonSave')" @click="saveHierarchy()"></Button>
      </div>
    </Dialog>

    <!-- <button @click="addRow()">Adicionar Linha</button>
    <button @click="deleteRow()">Excluir Linha Selecionada</button>
    <button @click="printFullData()" class="kkk" :ref="'kkk'">Debug</button>
    <label for="switch1">Copiar horizontal</label>
    <InputSwitch v-model="copyH" inputId="switch1" /> -->
    <ScrollPanel style="width: 100%; height: 65vh" class="scroll-custom-batch-incident-insert" ref="scrollPanel">
      <table id="editableTableBatchInsert" style="border-collapse: collapse !important;">
        <thead>
          <tr>
            <th class="table-corner-batch-insert stickyHead-batch-incident-insert"></th>
            <th class="hideColumnEntuba stickyHead-batch-incident-insert" style="border: none; border-bottom: 1px solid gray;"></th>
            <th :style="getColumnStyle('id')" style="border-left: none;" @click="selectCol(getColumnIndex('id'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center hideColumnEntuba">
              ID
              <div :class="[
                'drag-col-handle-' + getColumnIndex('id'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('risk')" @click="selectCol(getColumnIndex('risk'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center hideColumnValidate">
              {{ this.$langGetValue("risk") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('risk'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('status')" @click="selectCol(getColumnIndex('status'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center hideColumnValidate">
              Status
              <div :class="[
                'drag-col-handle-' + getColumnIndex('status'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('dateOfOccurrence')" @click="selectCol(getColumnIndex('dateOfOccurrence'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("dateOfOccurrence") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('dateOfOccurrence'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('dateOfDiscovery')" @click="selectCol(getColumnIndex('dateOfDiscovery'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("dateOfDiscovery") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('dateOfDiscovery'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('description')" @click="selectCol(getColumnIndex('description'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("description") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('description'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('hashtag')" @click="selectCol(getColumnIndex('hashtag'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("hashtag") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('hashtag'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('submittedTimestamp')" @click="selectCol(getColumnIndex('submittedTimestamp'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("dateOfRegistration") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('submittedTimestamp'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('baselLevel')" @click="selectCol(getColumnIndex('baselLevel'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("basel") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('baselLevel'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('riskFactor')" @click="selectCol(getColumnIndex('riskFactor'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              Risk Factor
              <div :class="[
                'drag-col-handle-' + getColumnIndex('riskFactor'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('system')" @click="selectCol(getColumnIndex('system'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("system") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('system'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('product')" @click="selectCol(getColumnIndex('product'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("products") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('product'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('company')" @click="selectCol(getColumnIndex('company'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("company") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('company'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('capability')" @click="selectCol(getColumnIndex('capability'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              Capability
              <div :class="[
                'drag-col-handle-' + getColumnIndex('capability'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('originatedHierarchy')" @click="selectCol(getColumnIndex('originatedHierarchy'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("originatedArea") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('originatedHierarchy'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('impactedHierarchy')" @click="selectCol(getColumnIndex('impactedHierarchy'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("impactedAreas") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('impactedHierarchy'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('ope01')" @click="selectCol(getColumnIndex('ope01'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("op01") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('ope01'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('ope02')" @click="selectCol(getColumnIndex('ope02'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("op02") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('ope02'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('reg01')" @click="selectCol(getColumnIndex('reg01'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("reg01") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('reg01'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('rep01')" @click="selectCol(getColumnIndex('rep01'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("rep01") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('rep01'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('rep02')" @click="selectCol(getColumnIndex('rep02'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("rep02") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('rep02'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('fin01')" @click="selectCol(getColumnIndex('fin01'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("fin01") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('fin01'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('fin02')" @click="selectCol(getColumnIndex('fin02'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("fin02") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('fin02'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('fin03_currency')" @click="selectCol(getColumnIndex('fin03_currency'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("fin03") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('fin03_currency'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('fin03_value')" @click="selectCol(getColumnIndex('fin03_value'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("fin03") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('fin03_value'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('fin04')" @click="selectCol(getColumnIndex('fin04'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("fin04") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('fin04'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('eventOrigin')" @click="selectCol(getColumnIndex('eventOrigin'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              Event Origin
              <div :class="[
                'drag-col-handle-' + getColumnIndex('eventOrigin'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('requestedBy')" @click="selectCol(getColumnIndex('requestedBy'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("requestedBy") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('requestedBy'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('statusUser')" @click="selectCol(getColumnIndex('statusUser'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              Status Forçado
              <div :class="[
                'drag-col-handle-' + getColumnIndex('statusUser'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('responsibleForReview')" @click="selectCol(getColumnIndex('responsibleForReview'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              Responsável pela revisão
              <div :class="[
                'drag-col-handle-' + getColumnIndex('responsibleForReview'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('riskUser')" @click="selectCol(getColumnIndex('riskUser'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              Nível de Risco
              <div :class="[
                'drag-col-handle-' + getColumnIndex('riskUser'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('riskUserJustification')"
              @click="selectCol(getColumnIndex('riskUserJustification'))" class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              Justificativa Nível de Risco
              <div :class="[
                'drag-col-handle-' + getColumnIndex('riskUserJustification'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('accountingDate')" @click="selectCol(getColumnIndex('accountingDate'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("accountingDate") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('accountingDate'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('managerialImpact')" @click="selectCol(getColumnIndex('managerialImpact'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("managerialImpact") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('managerialImpact'),
                'drag-col-handle',
              ]"></div>
            </th>
            <th :style="getColumnStyle('accountingAccounts')" @click="selectCol(getColumnIndex('accountingAccounts'))"
              class="col-index-batch-insert stickyHead-batch-incident-insert text-center">
              {{ this.$langGetValue("accountingAccount") }}
              <div :class="[
                'drag-col-handle-' + getColumnIndex('accountingAccounts'),
                'drag-col-handle',
              ]"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(line, index) in fullData" :key="line.id">
            <td @click="selectRow(index)" ref="stickyColumn" class="row-index-batch-insert text-center sticky-column">
              {{ index + 1 }}
            </td>
            <td class="hideColumnEntuba text-center"
              style="background: rgba(212, 212, 212, 0.308); cursor: pointer; padding: 5px;"
              @click="navigateToIncident(index)">
              <i class="pi pi-external-link" v-if="fullData[index].data[getColumnIndex('id')].data != null"></i>
            </td>
            <td class="td-custom-batch-insert hideColumnEntuba" @click="copyId(index)" style="cursor: pointer">
              <!-- @click="clickSelector($event, index, getColumnIndex('id'))" -->
              <div :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('id'),
              ]"></div>
              <div class="custom-input-batch-incident-insert" v-if="fullData[index].data[getColumnIndex('id')].data">
                <div class="cell-default-batch-insert" style="gap: 5px">
                  {{ fullData[index].data[getColumnIndex("id")].data }}
                  <i class="pi pi-copy" v-if="fullData[index].data[getColumnIndex('id')].data != null
                    "></i>
                </div>
              </div>
              <div :class="['drag-handle-batch-insert-' + index + '-' + getColumnIndex('id')]"></div>
            </td>
            <td class="td-custom-batch-insert hideColumnValidate">
              <div @click="clickSelector($event, index, getColumnIndex('risk'))" :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('risk'),
              ]"></div>
              <div class="custom-input-batch-incident-insert" style="padding: 5px" v-if="fullData[index].data[getColumnIndex('risk')].data">
                <div class="cell-default-batch-insert" style="
                    flex-direction: column;
                    gap: 10px;
                    justify-content: center;
                  ">
                  <div style="display: flex; width: 100%">
                    <Skeleton v-tooltip.top="{
                      value: getMarioSeverity(
                        fullData[index].data[getColumnIndex('risk')].data
                      ),
                    }" shape="circle" size="1rem" class="mr-2 mt-1" :style="{
  'background-color': getMarioSeverityColor(
    fullData[index].data[getColumnIndex('risk')].data
  ),
}" />
                    Calculado:
                    {{ fullData[index].data[getColumnIndex("risk")].data }}
                  </div>
                  <div v-if="fullData[index].data[getColumnIndex('riskUser')].data" style="display: flex; width: 100%">
                    <Skeleton v-tooltip.top="{
                      value: getMarioSeverity(
                        fullData[index].data[getColumnIndex('riskUser')].data
                          .id
                      ),
                    }" shape="circle" size="1rem" class="mr-2 mt-1" :style="{
  'background-color': getMarioSeverityColor(
    fullData[index].data[getColumnIndex('riskUser')].data
      .id
  ),
}" />
                    Forçado:
                    {{
                      fullData[index].data[getColumnIndex("riskUser")].data.id
                    }}
                  </div>
                </div>
              </div>
              <div :class="['drag-handle-batch-insert-' + index + '-' + getColumnIndex('risk')]"></div>
            </td>
            <td class="td-custom-batch-insert hideColumnValidate">
              <div @click="clickSelector($event, index, getColumnIndex('status'))" :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('status'),
              ]"></div>
              <div class="custom-input-batch-incident-insert" style="padding: 5px" v-if="fullData[index].data[getColumnIndex('status')].data">
                <div class="cell-default-batch-insert" style="
                    flex-direction: column;
                    gap: 10px;
                    justify-content: center;
                  ">
                  <div style="display: flex; width: 100%">
                    Calculado:
                    {{
                      fullData[index].data[getColumnIndex("status")].data.label
                    }}
                  </div>
                  <div v-if="fullData[index].data[getColumnIndex('statusUser')].data
                      " style="display: flex; width: 100%">
                    Forçado:
                    {{
                      fullData[index].data[getColumnIndex("statusUser")].data
                        .label
                    }}
                  </div>
                </div>
              </div>
              <div :class="[
                    'drag-handle-batch-insert-' + index + '-' + getColumnIndex('status'),
                  ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector(
                  $event,
                  index,
                  getColumnIndex('dateOfOccurrence')
                )
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('dateOfOccurrence'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Calendar :ref="'ref-input-' +
                  index +
                  '-' +
                  getColumnIndex('dateOfOccurrence')
                  " v-model="fullData[index].data[getColumnIndex('dateOfOccurrence')]
    .data
    " dateFormat="dd/mm/yy" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' +
                index +
                '-' +
                getColumnIndex('dateOfOccurrence'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector(
                  $event,
                  index,
                  getColumnIndex('dateOfDiscovery')
                )
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('dateOfDiscovery'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Calendar :ref="'ref-input-' +
                  index +
                  '-' +
                  getColumnIndex('dateOfDiscovery')
                  " v-model="fullData[index].data[getColumnIndex('dateOfDiscovery')].data
    " dateFormat="dd/mm/yy" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' +
                index +
                '-' +
                getColumnIndex('dateOfDiscovery'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector($event, index, getColumnIndex('description'))
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('description'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <InputText :ref="'ref-input-' + index + '-' + getColumnIndex('description')
                  " v-model="fullData[index].data[getColumnIndex('description')].data
    " style="width: 100%; height: 100%" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('description'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="clickSelector($event, index, getColumnIndex('hashtag'))" :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('hashtag'),
              ]"></div>
              <div class="custom-input-batch-incident-insert">
                <AutoComplete :ref="'ref-input-' + index + '-' + getColumnIndex('hashtag')"
                  v-model="fullData[index].data[getColumnIndex('hashtag')].data" multiple :suggestions="filteredHashtags"
                  @complete="searchHashtags($event)" optionLabel="label" :forceSelection="true"
                  style="height: 100%; width: 100%">
                  <template #item="slotProps">
                    <div class="flex align-options-center" v-if="slotProps.item.id">
                      <div>{{ slotProps.item.label }}</div>
                    </div>
                    <div v-else class="flex align-options-center hashtag-suggestion" v-tooltip.top="'Sugestão'">
                      <div>{{ slotProps.item.label }}</div>
                    </div>
                  </template>
                  <template #chip="slotProps">
                    <div class="flex align-options-center" v-if="slotProps.value.id">
                      <div>{{ slotProps.value.label }}</div>
                    </div>
                    <div v-else class="flex align-options-center hashtag-suggestion" v-tooltip.top="'Sugestão'">
                      <div>{{ slotProps.value.label }}</div>
                    </div>
                  </template>
                </AutoComplete>
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('hashtag'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector(
                  $event,
                  index,
                  getColumnIndex('submittedTimestamp')
                )
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('submittedTimestamp'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Calendar :ref="'ref-input-' +
                  index +
                  '-' +
                  getColumnIndex('submittedTimestamp')
                  " v-model="fullData[index].data[getColumnIndex('submittedTimestamp')]
    .data
    " dateFormat="dd/mm/yy" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' +
                index +
                '-' +
                getColumnIndex('submittedTimestamp'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector($event, index, getColumnIndex('baselLevel'))
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('baselLevel'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' + index + '-' + getColumnIndex('baselLevel')
                  " filter v-model="fullData[index].data[getColumnIndex('baselLevel')].data
    " :options="baselLevel" optionLabel="label" optionGroupLabel="label" optionGroupChildren="children" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('baselLevel'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector($event, index, getColumnIndex('riskFactor'))
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('riskFactor'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' + index + '-' + getColumnIndex('riskFactor')
                  " filter v-model="fullData[index].data[getColumnIndex('riskFactor')].data
    " :options="riskFactor" optionLabel="label" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('riskFactor'),
              ]"></div>
            </td>

            <!-- fim -->
            <td class="td-custom-batch-insert">
              <div @click="clickSelector($event, index, getColumnIndex('system'))" :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('system'),
              ]"></div>
              <div class="custom-input-batch-incident-insert">
                <MultiSelect :ref="'ref-input-' + index + '-' + getColumnIndex('system')" filter
                  v-model="fullData[index].data[getColumnIndex('system')].data" :options="system" optionLabel="label" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('system'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="clickSelector($event, index, getColumnIndex('product'))" :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('product'),
              ]"></div>
              <div class="custom-input-batch-incident-insert">
                <MultiSelect :ref="'ref-input-' + index + '-' + getColumnIndex('product')" filter
                  v-model="fullData[index].data[getColumnIndex('product')].data" :options="product" optionLabel="label" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('product'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="clickSelector($event, index, getColumnIndex('company'))" :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('company'),
              ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' + index + '-' + getColumnIndex('company')" filter
                  v-model="fullData[index].data[getColumnIndex('company')].data" :options="company" optionLabel="label"
                  optionGroupLabel="label" optionGroupChildren="children" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('company'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector($event, index, getColumnIndex('capability'))
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('capability'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' + index + '-' + getColumnIndex('capability')
                  " filter v-model="fullData[index].data[getColumnIndex('capability')].data
    " :options="capability" optionLabel="label" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('capability'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector(
                  $event,
                  index,
                  getColumnIndex('originatedHierarchy')
                )
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('originatedHierarchy'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <div style="height: 100%">
                  <div style="
                      display: flex;
                      flex-wrap: wrap;
                      gap: 3px;
                      margin: 3px;
                    ">
                    <Chip :ref="'ref-input-' +
                      index +
                      '-' +
                      getColumnIndex('originatedHierarchy')
                      " class="primaryChip customChipSize" style="padding: 4px 8px" v-for="label in fullData[index].data[
    getColumnIndex('originatedHierarchy')
  ].chips" :key="label">
                      {{ label }}
                    </Chip>
                  </div>
                </div>
              </div>
              <div :class="[
                'drag-handle-batch-insert-' +
                index +
                '-' +
                getColumnIndex('originatedHierarchy'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector(
                  $event,
                  index,
                  getColumnIndex('impactedHierarchy')
                )
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('impactedHierarchy'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <div style="height: 100%">
                  <div v-if="fullData[index].data[getColumnIndex('impactedHierarchy')]
                    .chips != null &&
                    fullData[index].data[getColumnIndex('impactedHierarchy')]
                      .chips.length == 0
                    " style="
                      align-items: center;
                      display: flex;
                      height: 100%;
                      justify-content: center;
                    ">
                    Clique para selecionar
                  </div>
                  <div style="
                      display: flex;
                      flex-wrap: wrap;
                      gap: 3px;
                      margin: 3px;
                    ">
                    <Chip :ref="'ref-input-' +
                      index +
                      '-' +
                      getColumnIndex('impactedHierarchy')
                      " class="primaryChip customChipSize" style="padding: 4px 8px" v-for="label in fullData[index].data[
    getColumnIndex('impactedHierarchy')
  ].chips" :key="label">
                      {{ label }}
                    </Chip>
                  </div>
                </div>
              </div>
              <div :class="[
                'drag-handle-batch-insert-' +
                index +
                '-' +
                getColumnIndex('impactedHierarchy'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="clickSelector($event, index, getColumnIndex('ope01'))" :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('ope01'),
              ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' + index + '-' + getColumnIndex('ope01')" filter
                  v-model="fullData[index].data[getColumnIndex('ope01')].data" :options="ope01" optionLabel="label" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('ope01'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="clickSelector($event, index, getColumnIndex('ope02'))" :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('ope02'),
              ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' + index + '-' + getColumnIndex('ope02')" filter
                  v-model="fullData[index].data[getColumnIndex('ope02')].data" :options="ope02" optionLabel="label" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('ope02'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="clickSelector($event, index, getColumnIndex('reg01'))" :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('reg01'),
              ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' + index + '-' + getColumnIndex('reg01')" filter
                  v-model="fullData[index].data[getColumnIndex('reg01')].data" :options="reg01" optionLabel="label" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('reg01'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="clickSelector($event, index, getColumnIndex('rep01'))" :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('rep01'),
              ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' + index + '-' + getColumnIndex('rep01')" filter
                  v-model="fullData[index].data[getColumnIndex('rep01')].data" :options="rep01" optionLabel="label" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('rep01'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="clickSelector($event, index, getColumnIndex('rep02'))" :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('rep02'),
              ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' + index + '-' + getColumnIndex('rep02')" filter
                  v-model="fullData[index].data[getColumnIndex('rep02')].data" :options="rep02" optionLabel="label" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('rep02'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="clickSelector($event, index, getColumnIndex('fin01'))" :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('fin01'),
              ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' + index + '-' + getColumnIndex('fin01')" filter
                  v-model="fullData[index].data[getColumnIndex('fin01')].data" :options="fin01" optionLabel="label" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('fin01'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="clickSelector($event, index, getColumnIndex('fin02'))" :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('fin02'),
              ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' + index + '-' + getColumnIndex('fin02')" filter
                  v-model="fullData[index].data[getColumnIndex('fin02')].data" :options="fin02" optionLabel="label" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('fin02'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector($event, index, getColumnIndex('fin03_currency'))
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('fin03_currency'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' +
                  index +
                  '-' +
                  getColumnIndex('fin03_currency')
                  " filter v-model="fullData[index].data[getColumnIndex('fin03_currency')].data
    " :options="fin03_currency" optionLabel="label" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' +
                index +
                '-' +
                getColumnIndex('fin03_currency'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector($event, index, getColumnIndex('fin03_value'))
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('fin03_value'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <InputNumber :ref="'ref-input-' + index + '-' + getColumnIndex('fin03_value')
                  " v-model="fullData[index].data[getColumnIndex('fin03_value')].data
    " :minFractionDigits="2" :maxFractionDigits="2" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('fin03_value'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="clickSelector($event, index, getColumnIndex('fin04'))" :class="[
                'selectable',
                'cell-' + index + '-' + getColumnIndex('fin04'),
              ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' + index + '-' + getColumnIndex('fin04')" filter
                  v-model="fullData[index].data[getColumnIndex('fin04')].data" :options="fin04" optionLabel="label" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('fin04'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector($event, index, getColumnIndex('eventOrigin'))
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('eventOrigin'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <InputText :ref="'ref-input-' + index + '-' + getColumnIndex('eventOrigin')
                  " v-model="fullData[index].data[getColumnIndex('eventOrigin')].data
    " />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('eventOrigin'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector($event, index, getColumnIndex('requestedBy'))
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('requestedBy'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <AutoComplete :ref="'ref-input-' + index + '-' + getColumnIndex('requestedBy')
                  " v-model="fullData[index].data[getColumnIndex('requestedBy')].data
    " :suggestions="filteredUsers" @complete="searchUsers($event)" :delay="600" :forceSelection="true" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('requestedBy'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector($event, index, getColumnIndex('statusUser'))
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('statusUser'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' + index + '-' + getColumnIndex('statusUser')
                  " filter v-model="fullData[index].data[getColumnIndex('statusUser')].data
    " :options="status" optionLabel="label" @change="valuateStatus(index)" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('statusUser'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector(
                  $event,
                  index,
                  getColumnIndex('responsibleForReview')
                )
                " :class="[
    'selectable',
    'cell-' +
    index +
    '-' +
    getColumnIndex('responsibleForReview'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <AutoComplete :ref="'ref-input-' +
                  index +
                  '-' +
                  getColumnIndex('responsibleForReview')
                  " v-model="fullData[index].data[getColumnIndex('responsibleForReview')]
    .data
    " :suggestions="filteredUsers" @complete="searchUsers($event)" :delay="600" :forceSelection="true" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' +
                index +
                '-' +
                getColumnIndex('responsibleForReview'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector($event, index, getColumnIndex('riskUser'))
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('riskUser'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Dropdown :ref="'ref-input-' + index + '-' + getColumnIndex('riskUser')" filter v-model="fullData[index].data[getColumnIndex('riskUser')].data
                  " :options="riskLevel" optionLabel="label" @change="valuateStatus(index)" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' + index + '-' + getColumnIndex('riskUser'),
              ]"></div>
            </td>
            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector(
                  $event,
                  index,
                  getColumnIndex('riskUserJustification')
                )
                " :class="[
    'selectable',
    'cell-' +
    index +
    '-' +
    getColumnIndex('riskUserJustification'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Textarea :ref="'ref-input-' +
                  index +
                  '-' +
                  getColumnIndex('riskUserJustification')
                  " v-model="fullData[index].data[
    getColumnIndex('riskUserJustification')
  ].data
    " style="width: 100%; height: 100%" cols="30" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' +
                index +
                '-' +
                getColumnIndex('riskUserJustification'),
              ]"></div>
            </td>

            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector($event, index, getColumnIndex('accountingDate'))
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('accountingDate'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Calendar :ref="'ref-input-' +
                  index +
                  '-' +
                  getColumnIndex('accountingDate')
                  " v-model="fullData[index].data[getColumnIndex('accountingDate')].data
    " dateFormat="dd/mm/yy" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' +
                index +
                '-' +
                getColumnIndex('accountingDate'),
              ]"></div>
            </td>

            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector(
                  $event,
                  index,
                  getColumnIndex('managerialImpact')
                )
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('managerialImpact'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <InputNumber :ref="'ref-input-' +
                  index +
                  '-' +
                  getColumnIndex('managerialImpact')
                  " v-model="fullData[index].data[getColumnIndex('managerialImpact')]
    .data
    " :minFractionDigits="2" :maxFractionDigits="2" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' +
                index +
                '-' +
                getColumnIndex('managerialImpact'),
              ]"></div>
            </td>

            <td class="td-custom-batch-insert">
              <div @click="
                clickSelector(
                  $event,
                  index,
                  getColumnIndex('accountingAccounts')
                )
                " :class="[
    'selectable',
    'cell-' + index + '-' + getColumnIndex('accountingAccounts'),
  ]"></div>
              <div class="custom-input-batch-incident-insert">
                <Textarea :ref="'ref-input-' +
                  index +
                  '-' +
                  getColumnIndex('accountingAccounts')
                  " v-model="fullData[index].data[getColumnIndex('accountingAccounts')]
    .data
    " style="width: 100%; height: 100%" cols="30" />
              </div>
              <div :class="[
                'drag-handle-batch-insert-' +
                index +
                '-' +
                getColumnIndex('accountingAccounts'),
              ]"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </ScrollPanel>
  </div>
  <div class="mt-4 card flex flex-column scrollDiv" v-if="errors.length > 0 || warnings.length > 0"
    style="max-height: 400px; gap: 5px; overflow: scroll">
    <div style="font-size: 13px; font-family: monospace">Console</div>
    <InlineMessage class="customInline" v-for="warning in warnings" severity="warn" :key="warning">{{ warning.value }}
    </InlineMessage>
    <InlineMessage class="customInline" v-for="erro in errors" severity="error" :key="erro">{{ erro.value }}
    </InlineMessage>
  </div>
</template>

<script>
import HierarchyComponent from "../components/HierarchyComponent.vue";
import IncidentService from "../service/IncidentService.js";
import FieldService from "../service/FieldService"
import GraphService from "../service/GraphService";
import MarioService from "../service/MarioService";
import UserService from "../service/UserService";
import { nextTick } from 'vue'
// import UserService from "../service/UserService";
import Util from "../service/Util";

export default {
  components: {
    HierarchyComponent,
  },
  data() {
    return {
      requestQueue: [], // Fila para armazenar as chamadas
      processingQueue: false, // Flag para verificar se a fila está sendo processada
      mailCache: [], // Cache dos emails já buscados
      underValidation: false,
      errors: [],
      warnings: [],
      pendingEntuba: true,
      page: 0,
      copyH: false,
      startDragCopyCross: null,
      startDragSelectable: null,
      startDragColResize: null,
      wasResizing: false,
      selectedCell: {
        rowStart: null,
        colStart: null,
        rowEnd: null,
        colEnd: null,
      },
      hierarchyMultipleVisible: false,
      hierarchySingleVisible: false,
      areaHierarchy: [],
      hierarchyArray: [],
      buttonPos: { row: null, col: null },
      hashtags: [],
      filteredHashtags: [],
      status: [
        {
          id: 1,
          label: "Fechado (Baixo)",
        },
        {
          id: 2,
          label: "Pendente Distribuição",
        },
        {
          id: 3,
          label: "Pendente Revisão",
        },
        {
          id: 4,
          label: "Revisado",
        },
      ],
      riskLevel: [
        {
          id: "low",
          label: "Baixo",
        },
        {
          id: "medium",
          label: "Médio",
        },
        {
          id: "high",
          label: "Alto",
        },
        {
          id: "critical",
          label: "Crítico",
        },
      ],
      columnMap: {
        id: {
          width: 120,
          type: "calculated",
        },
        risk: {
          width: 200,
          type: "calculated",
        },
        status: {
          width: 200,
          type: "calculated",
        },
        dateOfOccurrence: {
          width: 200,
          type: "date",
        },
        dateOfDiscovery: {
          width: 200,
          type: "date",
        },
        description: {
          width: 200,
          type: "text",
        },
        hashtag: {
          width: 400,
          type: "autoComplete",
        },
        submittedTimestamp: {
          width: 200,
          type: "date",
        },
        baselLevel: {
          width: 200,
          type: "dropdown",
        },
        riskFactor: {
          width: 200,
          type: "dropdown",
        },
        system: {
          width: 200,
          type: "multiselect",
        },
        product: {
          width: 200,
          type: "multiselect",
        },
        company: {
          width: 200,
          type: "dropdown",
        },
        capability: {
          width: 200,
          type: "dropdown",
        },
        originatedHierarchy: {
          width: 200,
          type: "dialog",
        },
        impactedHierarchy: {
          width: 200,
          type: "dialog",
        },
        ope01: {
          width: 200,
          type: "dropdown",
        },
        ope02: {
          width: 200,
          type: "dropdown",
        },
        reg01: {
          width: 200,
          type: "dropdown",
        },
        rep01: {
          width: 200,
          type: "dropdown",
        },
        rep02: {
          width: 200,
          type: "dropdown",
        },
        fin01: {
          width: 200,
          type: "dropdown",
        },
        fin02: {
          width: 200,
          type: "dropdown",
        },
        fin03_currency: {
          width: 200,
          type: "dropdown",
        },
        fin03_value: {
          width: 200,
          type: "float",
        },
        fin04: {
          width: 200,
          type: "dropdown",
        },
        eventOrigin: {
          width: 200,
          type: "text",
        },
        requestedBy: {
          width: 200,
          type: "user",
        },
        statusUser: {
          width: 200,
          type: "dropdown",
        },
        responsibleForReview: {
          width: 200,
          type: "user",
        },
        riskUser: {
          width: 200,
          type: "dropdown",
        },
        riskUserJustification: {
          width: 200,
          type: "text",
        },
        accountingDate: {
          width: 200,
          type: "text",
        },
        managerialImpact: {
          width: 200,
          type: "text",
        },
        accountingAccounts: {
          width: 200,
          type: "text",
        },
      },
      tableSize: 50,
      fullData: [],
      fullDataToShow: [],
      baselLevel: [],
      riskFactor: [],
      system: [],
      product: [],
      company: [],
      capability: [],
      ope01: null,
      ope02: null,
      reg01: null,
      rep01: null,
      rep02: null,
      fin01: null,
      fin02: null,
      fin03_currency: null,
      fin04: null,
      filteredUsers: [],
      allValidated: false,
      delay: 700,
      clicks: 0,
      lastClickedCell: { row: 0, col: 0 },
      timer: null,
      isEditing: false,
      instructionsCheck: false,
    };
  },
  unmounted() {
    const scrollPanel = this.$refs.scrollPanel ? this.$refs.scrollPanel.$el : null;
    if (scrollPanel) {
      const scrollableElement = scrollPanel.querySelector('.p-scrollpanel-content');
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', this.handleScroll);
      }
    }
    document.removeEventListener("paste", this.pasteCells);
    document.removeEventListener("copy", this.copyCells);
    document.removeEventListener("mousedown", this.mouseDown);
    document.removeEventListener("mousemove", this.mouseMove);
    document.removeEventListener("mouseup", this.mouseUp);
    document.removeEventListener("keydown", this.keyboardHandler);
  },
  mounted() {
    this.$nextTick(() => {
      const scrollPanel = this.$refs.scrollPanel.$el;

      if (scrollPanel) {
        const scrollableElement = scrollPanel.querySelector('.p-scrollpanel-content');

        if (scrollableElement) {
          scrollableElement.addEventListener('scroll', this.handleScroll);
        }
      }
    });

    document.addEventListener("paste", this.pasteCells);
    document.addEventListener("copy", this.copyCells);
    document.addEventListener("mousedown", this.mouseDown);
    document.addEventListener("mousemove", this.mouseMove);
    document.addEventListener("mouseup", this.mouseUp);
    document.addEventListener("keydown", this.keyboardHandler);
    // this.blockCell(0,0)
  },
  created() { },
  watch: {},
  methods: {
    async mountTable() {
      Util.loadRequest();

      Array.from(document.querySelectorAll(".selected-editting-batch-insert")).forEach(
        (el) => el.classList.remove("selected-editting-batch-insert")
      );
      Array.from(document.querySelectorAll(".warning")).forEach(
        (el) => el.classList.remove("warning-batch-incident-insert")
      );
      Array.from(document.querySelectorAll(".validated-batch-insert")).forEach((el) =>
        el.classList.remove("validated-batch-insert")
      );
      Array.from(document.querySelectorAll(".errorValidated-batch-insert")).forEach((el) =>
        el.classList.remove("errorValidated-batch-insert")
      );

      await new Promise(resolve => setTimeout(resolve, 200));

      this.fullData = [];
      for (let i = 0; i < this.tableSize; i++) {
        this.fullData.push({
          id: 0,
          data: [
            { data: null, blocked: false }, // id
            { data: null, blocked: false }, // risk
            { data: null, blocked: false }, // status
            { data: null, blocked: false }, // dateOfOccurrence
            { data: null, blocked: false }, // dateOfDiscovery
            { data: null, blocked: false }, // description
            { data: null, blocked: false }, // hashtag
            { data: null, blocked: false }, // submittedTimestamp
            { data: null, blocked: false }, // baselLevel
            { data: null, blocked: false }, // riskFactor
            { data: null, blocked: false }, // system
            { data: null, blocked: false }, // product
            { data: null, blocked: false }, // company
            { data: null, blocked: false }, // capability
            { data: null, chips: null, blocked: false }, // originatedHierarchy
            { data: null, chips: null, blocked: false }, // impactedHierarchy
            { data: null, blocked: false }, // ope01
            { data: null, blocked: false }, // ope02
            { data: null, blocked: false }, // reg01
            { data: null, blocked: false }, // rep01
            { data: null, blocked: false }, // rep02
            { data: null, blocked: false }, // fin01
            { data: null, blocked: false }, // fin02
            { data: null, blocked: false }, // fin03_currency
            { data: null, blocked: false }, // fin03_value
            { data: null, blocked: false }, // fin04
            { data: null, blocked: false }, // eventOrigin
            { data: null, blocked: false }, // requestedBy
            { data: null, blocked: false }, // statusUser
            { data: null, blocked: false }, // responsibleForReview
            { data: null, blocked: false }, // riskUser
            { data: null, blocked: false }, // riskUserJustification
            { data: null, blocked: false }, // Managerial Impact
            { data: null, blocked: false }, // Accounting Date
            { data: null, blocked: false }, // Accounting Accounts
          ],
        });
      }

      this.pendingEntuba = true;

      this.fullDataToShow = this.fullData.slice(0, 50);

      // this.hashtags = FieldService.data.incident.hashtag;
      // this.baselLevel = FieldService.data.incident.baselLevel.sort((a, b) => a.label.localeCompare(b.label));
      // this.riskFactor = FieldService.data.incident.riskFactor;
      // this.system = FieldService.data.incident.system.sort((a, b) => a.label.localeCompare(b.label));
      // this.product = FieldService.data.incident.product.sort((a, b) => a.label.localeCompare(b.label));

      // const npIndex = this.product.findIndex((item) => item.id === 607);
      // if (npIndex !== -1) {
      //   [this.product[0], this.product[npIndex]] = [this.product[npIndex], this.product[0]];
      // }

      // this.company = FieldService.data.bia.company.sort((a, b) => { if (a.id === 43) return -1; if (b.id === 43) return 1; return 0; });

      // this.capability = FieldService.data.incident.capability.sort((a, b) => a.label.localeCompare(b.label));
      // this.ope01 = FieldService.data.incident.marioOpe01.sort((a, b) => a.data.stormId.localeCompare(b.data.stormId));
      // this.ope02 = FieldService.data.incident.marioOpe02.sort((a, b) => a.data.stormId.localeCompare(b.data.stormId));
      // this.reg01 = FieldService.data.incident.marioReg01.sort((a, b) => a.data.stormId.localeCompare(b.data.stormId));
      // this.rep01 = FieldService.data.incident.marioRep01.sort((a, b) => a.data.stormId.localeCompare(b.data.stormId));
      // this.rep02 = FieldService.data.incident.marioRep02.sort((a, b) => a.data.stormId.localeCompare(b.data.stormId));
      // this.fin01 = FieldService.data.incident.marioFin01.sort((a, b) => a.data.stormId.localeCompare(b.data.stormId));
      // this.fin02 = FieldService.data.incident.marioFin02.sort((a, b) => a.data.stormId.localeCompare(b.data.stormId));
      // this.fin03_currency = FieldService.data.incident.currency;
      // this.fin04 = FieldService.data.incident.marioFin04;

      this.areaHierarchy = FieldService.getField('incident', 'originatedArea');
      this.hashtags = FieldService.getField('incident', 'hashtag');
      this.baselLevel = FieldService.getField('incident', 'baselLevel');
      this.riskFactor = FieldService.getField('incident', 'riskFactor');
      this.system = FieldService.getField('incident', 'system');
      this.product = FieldService.getField('incident', 'product');
      this.capability = FieldService.getField('incident', 'capability');
      this.company = FieldService.getField('incident', 'company');
      this.ope01 = FieldService.getField('incident', 'marioOpe01');
      this.ope02 = FieldService.getField('incident', 'marioOpe02');
      this.rep01 = FieldService.getField('incident', 'marioRep01');
      this.rep02 = FieldService.getField('incident', 'marioRep02');
      this.reg01 = FieldService.getField('incident', 'marioReg01');
      this.fin01 = FieldService.getField('incident', 'marioFin01');
      this.fin02 = FieldService.getField('incident', 'marioFin02');
      this.fin04 = FieldService.getField('incident', 'marioFin04');
      this.fin03_currency = FieldService.getField('incident', 'marioFin03Currency');

      this.populateHierarchyArray();
      await nextTick(); // Certifique-se de que as atualizações do DOM foram concluídas

      for (let i = 0; i < this.fullData.length; i++) {
        this.blockCell(i, 0);
        this.blockCell(i, 1);
        this.blockCell(i, 2);
      }

      const hiddenColumnsEntuba = document.querySelectorAll('.hideColumnEntuba');
      hiddenColumnsEntuba.forEach((column) => {
        column.style.display = 'none';
      });

      const hiddenColumnsValidate = document.querySelectorAll('.hideColumnValidate');
      hiddenColumnsValidate.forEach((column) => {
        column.style.display = 'none';
      });

      Util.loadedRequest();
    },
    handleScroll() {
      const scrollLeft = this.$refs.scrollPanel.$el.querySelector('.p-scrollpanel-content').scrollLeft;
      this.updateStickyColumns(scrollLeft);
    },
    updateStickyColumns(scrollLeft) {
      const stickyColumns = document.querySelectorAll('.sticky-column');

      stickyColumns.forEach((column) => {
        column.style.transform = `translateX(${scrollLeft}px)`;
        column.style.zIndex = '1000';
      });
    },
    queueRequest(row, col, email) {
      this.requestQueue.push({ row, col, email });

      if (!this.processingQueue) {
        this.processQueue();
      }
    },
    async processQueue() {
      this.processingQueue = true;

      while (this.requestQueue.length > 0) {
        const { row, col, email } = this.requestQueue.shift();

        console.log(this.mailCache)
        let cachedEmail = this.mailCache.find((cached) => cached.includes(email));

        if (cachedEmail) {
          this.setCellData(row, col, cachedEmail);
        } else {
          try {
            const response = await GraphService.searchUser(email, "users");
            if (response.length === 1) {
              const data = response[0].mail.toLowerCase();
              this.mailCache.push(data);
              this.setCellData(row, col, data);
            }
          } catch (error) {
            console.error(`Erro ao buscar o usuário para o email: ${email}`, error);
          }
        }
      }

      this.processingQueue = false;
    },
    getLastRow() {
      let lastRow = 0;
      for (let i = 0; i < this.fullData.length; i++) {
        let hasData = false;
        for (let j = 0; j < this.fullData[i].data.length; j++) {
          if (this.fullData[i].data[j].data != null) {
            hasData = true;
          }
        }
        if (hasData) {
          lastRow++;
        } else {
          break;
        }
      }
      return lastRow;
    },
    validate() {
      let lastRow = this.getLastRow();

      if (lastRow == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Entuba vazio!",
          life: 1500,
        });
        return;
      }

      this.underValidation = true;
      Util.loading = true;
      this.errors = [];

      setTimeout(async () => {
        let hasError = false;

        Array.from(document.querySelectorAll(".selected-editting-batch-insert")).forEach(
          (el) => el.classList.remove("selected-editting-batch-insert")
        );
        Array.from(document.querySelectorAll(".warning")).forEach(
          (el) => el.classList.remove("warning-batch-incident-insert")
        );
        Array.from(document.querySelectorAll(".validated-batch-insert")).forEach((el) =>
          el.classList.remove("validated-batch-insert")
        );
        Array.from(document.querySelectorAll(".errorValidated-batch-insert")).forEach((el) =>
          el.classList.remove("errorValidated-batch-insert")
        );

        for (let i = 0; i < lastRow; i++) {
          this.fullData[i].data[this.getColumnIndex("risk")].data = null;
          for (let j = 0; j < this.fullData[0].data.length; j++) {
            if (this.fullData[i].data[j].blocked) {
              continue;
            }
            let hasErrorTmp = this.validateCell(i, j);
            if (!hasError) {
              hasError = hasErrorTmp;
            }
          }
          if (hasError) {
            continue;
          }
        }

        if (hasError) {
          this.allValidated = false;
          Util.loading = false;
          this.underValidation = false;
          return;
        }

        const promises = [];
        for (let i = 0; i < lastRow; i++) {
          promises.push(this.calculateRisk(i));
        }

        await Promise.all(promises);

        const hiddenColumns = document.querySelectorAll('.hideColumnValidate');

        hiddenColumns.forEach((column) => {
          column.style.display = '';
        });

        Util.loading = false;
        this.underValidation = false;
        this.allValidated = true;

      }, 200);
    },
    calculateRisk(row) {
      return new Promise((resolve) => {
        try {
          let tempMarioPayload = {
            ope01: {
              id: this.fullData[row].data[this.getColumnIndex("ope01")]?.data?.id,
            },
            ope02: {
              id: this.fullData[row].data[this.getColumnIndex("ope02")]?.data?.id,
            },
            reg01: {
              id: this.fullData[row].data[this.getColumnIndex("reg01")]?.data?.id,
            },
            rep01: {
              id: this.fullData[row].data[this.getColumnIndex("rep01")]?.data?.id,
            },
            rep02: {
              id: this.fullData[row].data[this.getColumnIndex("rep02")]?.data?.id,
            },
            fin01: {
              id: this.fullData[row].data[this.getColumnIndex("fin01")]?.data?.id,
            },
            fin02: {
              id: this.fullData[row].data[this.getColumnIndex("fin02")]?.data?.id,
            },
            fin04: {
              id: this.fullData[row].data[this.getColumnIndex("fin04")]?.data?.id,
            },
            fin03:
              this.fullData[row].data[this.getColumnIndex("fin03_currency")]
                .data != null
                ? {
                  currency:
                    this.fullData[row].data[
                      this.getColumnIndex("fin03_currency")
                    ].data != null
                      ? this.fullData[row].data[
                        this.getColumnIndex("fin03_currency")
                      ].data.id
                      : null,
                  value:
                    this.fullData[row].data[this.getColumnIndex("fin03_value")]
                      .data != null
                      ? this.fullData[row].data[
                        this.getColumnIndex("fin03_value")
                      ].data
                      : 0,
                }
                : null,
            obu:
              this.fullData[row].data[this.getColumnIndex("originatedHierarchy")]
                .data != null
                ? this.fullData[row].data[
                  this.getColumnIndex("originatedHierarchy")
                ].data[0]?.id
                : null,
          };

          var tempImpactedHierarchy = [];

          this.fullData[row].data[
            this.getColumnIndex("impactedHierarchy")
          ].data?.forEach((area) => {
            tempImpactedHierarchy.push(area.id);
          });

          tempMarioPayload["ibu"] = tempImpactedHierarchy;

          MarioService.getScore(tempMarioPayload).then((resp) => {
            if (resp.success) {
              this.fullData[row].data[this.getColumnIndex("risk")].data =
                resp.data.risk_level;
              this.valuateStatus(row);
            }
            resolve();
          }).catch((error) => {
            console.error("Ocorreu um erro:", error);
            resolve();
          });
        } catch (error) {
          console.error("Ocorreu um erro:", error);
          resolve();
        }
      });
    },
    validateCell(row, col) {
      let hasError = false;
      switch (Object.keys(this.columnMap)[col]) {
        case "dateOfOccurrence": {
          if (
            this.fullData[row].data[col].data > new Date() ||
            this.fullData[row].data[col].data == null
          ) {
            hasError = true;
            this.errors.push({
              id:
                row.toString() +
                this.getColumnIndex("dateOfOccurrence").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("dateOfOccurrence"),
            });
          } else {
            hasError = false;
          }
          break;
        }
        case "dateOfDiscovery": {
          let dateOfOccurrence =
            this.fullData[row].data[this.getColumnIndex("dateOfOccurrence")];
          if (
            dateOfOccurrence.data > this.fullData[row].data[col].data ||
            this.fullData[row].data[col].data > new Date()
          ) {
            hasError = true;
            this.errors.push({
              id:
                row.toString() +
                this.getColumnIndex("dateOfDiscovery").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("dateOfDiscovery"),
            });
          } else {
            hasError = false;
          }
          break;
        }
        case "submittedTimestamp": {
          let dateOfDiscovery =
            this.fullData[row].data[this.getColumnIndex("dateOfDiscovery")];

          if (this.fullData[row].data[col].data == null) {
            hasError = false;
          } else if (
            dateOfDiscovery.data > this.fullData[row].data[col].data ||
            this.fullData[row].data[col].data > new Date()
          ) {
            hasError = true;
            this.errors.push({
              id:
                row.toString() +
                this.getColumnIndex("submittedTimestamp").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("dateOfRegistration"),
            });
          } else {
            hasError = false;
          }

          break;
        }
        case "baselLevel": {
          if (this.fullData[row].data[col].data != null) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id: row.toString() + this.getColumnIndex("baselLevel").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("basel"),
            });
          }
          break;
        }
        case "company": {
          if (this.fullData[row].data[col].data != null) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id: row.toString() + this.getColumnIndex("company").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("company"),
            });
          }
          break;
        }
        case "capability": {
          if (this.fullData[row].data[col].data != null) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id: row.toString() + this.getColumnIndex("capability").toString(),
              value:
                "(erro) Linha: " + (row + 1) + " ; Coluna: " + "Capability",
            });
          }
          break;
        }
        case "riskFactor": {
          if (this.fullData[row].data[col].data != null) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id: row.toString() + this.getColumnIndex("riskFactor").toString(),
              value:
                "(erro) Linha: " + (row + 1) + " ; Coluna: " + "Risk Factor",
            });
          }
          break;
        }
        case "ope01": {
          if (this.fullData[row].data[col].data != null) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id: row.toString() + this.getColumnIndex("ope01").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("op01"),
            });
          }
          break;
        }
        case "ope02": {
          if (this.fullData[row].data[col].data != null) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id: row.toString() + this.getColumnIndex("ope02").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("op02"),
            });
          }
          break;
        }
        case "reg01": {
          if (this.fullData[row].data[col].data != null) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id: row.toString() + this.getColumnIndex("reg01").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("reg01"),
            });
          }
          break;
        }
        case "rep01": {
          if (this.fullData[row].data[col].data != null) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id: row.toString() + this.getColumnIndex("rep01").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("rep01"),
            });
          }
          break;
        }
        case "rep02": {
          if (this.fullData[row].data[col].data != null) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id: row.toString() + this.getColumnIndex("rep02").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("rep02"),
            });
          }
          break;
        }
        case "fin01": {
          if (this.fullData[row].data[col].data != null) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id: row.toString() + this.getColumnIndex("fin01").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("fin01"),
            });
          }
          break;
        }
        case "requestedBy": {
          if (this.fullData[row].data[col].data != null) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id:
                row.toString() + this.getColumnIndex("requestedBy").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("requestedBy"),
            });
          }
          break;
        }
        case "description": {
          if (
            this.fullData[row].data[col].data != null &&
            this.fullData[row].data[col].data.length > 20
          ) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id:
                row.toString() + this.getColumnIndex("description").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("description"),
            });
          }
          break;
        }
        case "eventOrigin": {
          hasError = false;
          // if (
          //   this.fullData[row].data[col].data != null &&
          //   this.fullData[row].data[col].data.length > 10
          // ) {
          //   hasError = false;
          // } else {
          //   hasError = true;
          // }
          break;
        }
        case "hashtag": {
          if (this.fullData[row].data[col].data != null &&
            Array.isArray(this.fullData[row].data[col].data) &&
            !this.fullData[row].data[col].data.includes(null) &&
            this.fullData[row].data[col].data.length > 0) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id: row.toString() + this.getColumnIndex("hashtag").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: hashtag",
            });
          }
          break;
        }
        case "product": {
          if (this.fullData[row].data[col].data != null &&
            Array.isArray(this.fullData[row].data[col].data) &&
            !this.fullData[row].data[col].data.includes(null) &&
            this.fullData[row].data[col].data.length > 0) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id: row.toString() + this.getColumnIndex("product").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("product"),
            });
          }
          break;
        }
        case "originatedHierarchy": {
          let riskFactor =
            this.fullData[row].data[this.getColumnIndex("riskFactor")];
          if (
            riskFactor?.data?.id != 4 &&
            this.fullData[row].data[col].data == null
          ) {
            hasError = true;
            this.errors.push({
              id:
                row.toString() +
                this.getColumnIndex("originatedHierarchy").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("originatedArea"),
            });
          } else {
            hasError = false;
          }
          break;
        }
        case "impactedHierarchy": {
          if (
            this.fullData[row].data[col].data != null &&
            this.fullData[row].data[col].data.length > 0
          ) {
            hasError = false;
          } else {
            hasError = true;
            this.errors.push({
              id:
                row.toString() +
                this.getColumnIndex("impactedHierarchy").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                this.$langGetValue("impactedAreas"),
            });
          }
          break;
        }
        case "system": {
          let riskFactor = this.fullData[row].data[this.getColumnIndex("riskFactor")];

          if (riskFactor && riskFactor?.data?.id == 1) {
            if (
              this.fullData[row].data[col].data != null &&
              Array.isArray(this.fullData[row].data[col].data) &&
              this.fullData[row].data[col].data.length > 0 &&
              !this.fullData[row].data[col].data.includes(null)
            ) {
              hasError = false;
            } else {
              hasError = true;
              this.errors.push({
                id: row.toString() + this.getColumnIndex("system").toString(),
                value:
                  "(erro) Linha: " + (row + 1) + " ; Coluna: " + this.$langGetValue("system"),
              });
            }
          }
          else if (riskFactor && riskFactor?.data?.id != 1) {
            if (
              this.fullData[row].data[col].data != null &&
              Array.isArray(this.fullData[row].data[col].data) &&
              this.fullData[row].data[col].data.includes(null)
            ) {
              hasError = true;
              this.errors.push({
                id: row.toString() + this.getColumnIndex("system").toString(),
                value:
                  "(erro) Linha: " + (row + 1) + " ; Coluna: " + this.$langGetValue("system"),
              });
            } else {
              hasError = false;
            }
          }
          else {
            hasError = false;
          }
          break;
        }
        case "fin02": {
          let fin01 = this.fullData[row].data[this.getColumnIndex("fin01")];
          if (fin01.data && fin01?.data?.id == 4) {
            if (this.fullData[row].data[col].data != null) {
              hasError = false;
            } else {
              hasError = true;
              this.errors.push({
                id: row.toString() + this.getColumnIndex("fin02").toString(),
                value:
                  "(erro) Linha: " +
                  (row + 1) +
                  " ; Coluna: " +
                  this.$langGetValue("fin01"),
              });
            }
          } else {
            if (this.fullData[row].data[col].data != null) {
              hasError = true;
              this.errors.push({
                id: row.toString() + this.getColumnIndex("fin02").toString(),
                value:
                  "(erro) Linha: " +
                  (row + 1) +
                  " ; Coluna: " +
                  this.$langGetValue("fin01"),
              });
            } else {
              hasError = false;
            }
          }
          break;
        }
        case "fin03_value": {
          let fin01 = this.fullData[row].data[this.getColumnIndex("fin01")];
          let fin02 = this.fullData[row].data[this.getColumnIndex("fin02")];
          if (
            fin01.data &&
            (fin01?.data?.id == 1 ||
              fin01?.data?.id == 2 ||
              fin01?.data?.id == 3)
          ) {
            if (this.fullData[row].data[col].data != null) {
              hasError = false;
            } else {
              hasError = true;
              this.errors.push({
                id: row.toString() + this.getColumnIndex("fin03").toString(),
                value:
                  "(erro) Linha: " +
                  (row + 1) +
                  " ; Coluna: " +
                  this.$langGetValue("fin03"),
              });
            }
          } else {
            if (this.fullData[row].data[col].data != null) {
              hasError = true;
              this.errors.push({
                id: row.toString() + this.getColumnIndex("fin03").toString(),
                value:
                  "(erro) Linha: " +
                  (row + 1) +
                  " ; Coluna: " +
                  this.$langGetValue("fin03"),
              });
            } else {
              hasError = false;
            }
          }
          if (
            fin02.data &&
            (fin02?.data?.id == 101 || fin02?.data?.id == 102)
          ) {
            if (this.fullData[row].data[col].data != null) {
              hasError = false;
            } else {
              hasError = true;
              this.errors.push({
                id: row.toString() + this.getColumnIndex("fin03").toString(),
                value:
                  "(erro) Linha: " +
                  (row + 1) +
                  " ; Coluna: " +
                  this.$langGetValue("fin03"),
              });
            }
          }
          break;
        }
        case "fin03_currency": {
          let fin01 = this.fullData[row].data[this.getColumnIndex("fin01")];
          let fin02 = this.fullData[row].data[this.getColumnIndex("fin02")];
          if (
            fin01.data &&
            (fin01?.data?.id == 1 ||
              fin01?.data?.id == 2 ||
              fin01?.data?.id == 3)
          ) {
            if (this.fullData[row].data[col].data != null) {
              hasError = false;
            } else {
              hasError = true;
              this.errors.push({
                id: row.toString() + this.getColumnIndex("fin03").toString(),
                value:
                  "(erro) Linha: " +
                  (row + 1) +
                  " ; Coluna: " +
                  this.$langGetValue("fin03"),
              });
            }
          } else {
            if (this.fullData[row].data[col].data != null) {
              hasError = true;
              this.errors.push({
                id: row.toString() + this.getColumnIndex("fin03").toString(),
                value:
                  "(erro) Linha: " +
                  (row + 1) +
                  " ; Coluna: " +
                  this.$langGetValue("fin03"),
              });
            } else {
              hasError = false;
            }
          }
          if (
            fin02.data &&
            (fin02?.data?.id == 101 || fin02?.data?.id == 102)
          ) {
            if (this.fullData[row].data[col].data != null) {
              hasError = false;
            } else {
              hasError = true;
              this.errors.push({
                id: row.toString() + this.getColumnIndex("fin03").toString(),
                value:
                  "(erro) Linha: " +
                  (row + 1) +
                  " ; Coluna: " +
                  this.$langGetValue("fin03"),
              });
            }
          }
          break;
        }
        case "fin04": {
          let fin01 = this.fullData[row].data[this.getColumnIndex("fin01")];
          if (fin01.data && (fin01?.data?.id == 1 || fin01?.data?.id == 3)) {
            if (this.fullData[row].data[col].data != null) {
              hasError = false;
            } else {
              hasError = true;
              this.errors.push({
                id: row.toString() + this.getColumnIndex("fin04").toString(),
                value:
                  "(erro) Linha: " +
                  (row + 1) +
                  " ; Coluna: " +
                  this.$langGetValue("fin04"),
              });
            }
          } else {
            if (this.fullData[row].data[col].data != null) {
              hasError = true;
              this.errors.push({
                id: row.toString() + this.getColumnIndex("fin04").toString(),
                value:
                  "(erro) Linha: " +
                  (row + 1) +
                  " ; Coluna: " +
                  this.$langGetValue("fin04"),
              });
            } else {
              hasError = false;
            }
          }
          break;
        }
        case "statusUser": {
          if (this.fullData[row].data[col].data == null) {
            hasError = false;
          } else {
            if (
              this.fullData[row].data[this.getColumnIndex("riskUser")].data !=
              null
            ) {
              if (
                this.fullData[row].data[this.getColumnIndex("riskUser")].data
                  .id != "low" &&
                this.fullData[row].data[this.getColumnIndex("statusUser")].data
                  .id == 1
              ) {
                hasError = true;
                this.errors.push({
                  id:
                    row.toString() +
                    this.getColumnIndex("statusUser").toString(),
                  value:
                    "(erro) Linha: " +
                    (row + 1) +
                    " ; Coluna: " +
                    "Status Forçado",
                });
              } else {
                hasError = false;
              }
            } else {
              if (
                this.fullData[row].data[this.getColumnIndex("risk")].data !=
                null
              ) {
                if (
                  this.fullData[row].data[this.getColumnIndex("risk")].data
                    ?.id != "low" &&
                  this.fullData[row].data[this.getColumnIndex("statusUser")]
                    .data.id == 1
                ) {
                  hasError = true;
                  this.errors.push({
                    id:
                      row.toString() +
                      this.getColumnIndex("statusUser").toString(),
                    value:
                      "(erro) Linha: " +
                      (row + 1) +
                      " ; Coluna: " +
                      "Status Forçado",
                  });
                } else {
                  hasError = false;
                }
              }
            }
          }
          break;
        }
        case "responsibleForReview": {
          let statusUser =
            this.fullData[row].data[this.getColumnIndex("statusUser")];
          if (
            ((statusUser?.data?.id == 3 || statusUser?.data?.id == 4) &&
              this.fullData[row].data[col].data == null) ||
            (statusUser?.data?.id != 3 &&
              statusUser?.data?.id != 4 &&
              this.fullData[row].data[col].data != null)
          ) {
            hasError = true;
            this.errors.push({
              id:
                row.toString() +
                this.getColumnIndex("responsibleForReview").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                "Responsável pela revisão",
            });
          } else {
            hasError = false;
          }
          break;
        }
        case "riskUser": {
          hasError = false;
          break;
        }
        case "riskUserJustification": {
          let riskUser =
            this.fullData[row].data[this.getColumnIndex("riskUser")];
          if (
            riskUser.data != null &&
            (this.fullData[row].data[col].data == null ||
              this.fullData[row].data[col].data.length < 20)
          ) {
            hasError = true;
            this.errors.push({
              id:
                row.toString() +
                this.getColumnIndex("riskUserJustification").toString(),
              value:
                "(erro) Linha: " +
                (row + 1) +
                " ; Coluna: " +
                "Justificativa Nível de Risco",
            });
          } else {
            hasError = false;
          }
          break;
        }
      }
      if (hasError) {
        document
          .getElementsByClassName("cell-" + row + "-" + col)[0]
          .classList.add("errorValidated-batch-insert");
      } else {
        document
          .getElementsByClassName("cell-" + row + "-" + col)[0]
          .classList.add("validated-batch-insert");
      }
      return hasError;
    },
    valuateStatus(row) {
      if (this.fullData[row].data[this.getColumnIndex("risk")].data != null) {
        if (
          this.fullData[row].data[this.getColumnIndex("riskUser")].data != null
        ) {
          if (
            this.fullData[row].data[this.getColumnIndex("riskUser")].data.id ==
            "low"
          ) {
            this.fullData[row].data[this.getColumnIndex("status")].data =
              this.status[0];
          } else {
            this.fullData[row].data[this.getColumnIndex("status")].data =
              this.status[1];
          }
        } else {
          if (
            this.fullData[row].data[this.getColumnIndex("risk")].data == "low"
          ) {
            this.fullData[row].data[this.getColumnIndex("status")].data =
              this.status[0];
          } else {
            this.fullData[row].data[this.getColumnIndex("status")].data =
              this.status[1];
          }
        }
      }
    },
    getMarioSeverity(risk) {
      if (risk == "low") {
        return this.$langGetValue("lowMario");
      } else if (risk == "medium") {
        return this.$langGetValue("mediumMario");
      } else if (risk == "high") {
        return this.$langGetValue("highMario");
      } else if (risk == "critical") {
        return this.$langGetValue("criticalMario");
      }
    },
    getMarioSeverityColor(risk) {
      return MarioService.getMarioSeverityColor(risk);
    },
    entuba() {
      Util.loading = true;

      this.warnings = []

      const hiddenColumns = document.querySelectorAll('.hideColumnEntuba');

      hiddenColumns.forEach((column) => {
        column.style.display = '';
      });

      setTimeout(() => {
        var generalPayload = [];

        for (let row = 0; row < this.getLastRow(); row++) {
          generalPayload.push(this.mountPayload(this.fullData[row].data));
        }

        IncidentService.batchIncident(generalPayload).then((response) => {
          if (response.success) {
            this.pendingEntuba = false;

            for (let row = 0; row < this.getLastRow(); row++) {
              this.fullData[row].data[this.getColumnIndex("id")].data =
                response.data[row].id;
            }
            this.$toast.add({
              severity: "success",
              summary: "Entuba concluído!",
              life: 1500,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Erro ao entubar!",
              life: 1500,
            });
          }
          Util.loading = false;
        });
      }, 200)
    },
    mountPayload(row) {
      var payload = {
        companyRole: UserService.company,
        submittedTimestamp:
          row[this.getColumnIndex("submittedTimestamp")].data != null
            ? row[this.getColumnIndex("submittedTimestamp")].data
            : new Date(),
        dateOfOccurrence: row[this.getColumnIndex("dateOfOccurrence")].data,
        dateOfDiscovery: row[this.getColumnIndex("dateOfDiscovery")].data,
        registeredBy: UserService.user,
        requestedBy: row[this.getColumnIndex("requestedBy")].data,
        eventOrigin: row[this.getColumnIndex("eventOrigin")].data,
        ope01: row[this.getColumnIndex("ope01")].data?.id ?? null,
        ope02: row[this.getColumnIndex("ope02")].data?.id ?? null,
        reg01: row[this.getColumnIndex("reg01")].data?.id ?? null,
        rep01: row[this.getColumnIndex("rep01")].data?.id ?? null,
        rep02: row[this.getColumnIndex("rep02")].data?.id ?? null,
        fin01: row[this.getColumnIndex("fin01")].data?.id ?? null,
        fin02: row[this.getColumnIndex("fin02")].data?.id ?? null,
        fin04: row[this.getColumnIndex("fin04")].data?.id ?? null,
        currency: row[this.getColumnIndex("fin03_currency")].data?.id,
        amount: row[this.getColumnIndex("fin03_value")].data,
        riskFactor: row[this.getColumnIndex("riskFactor")].data?.id,
        capability: row[this.getColumnIndex("capability")].data?.id,
        company: row[this.getColumnIndex("company")].data?.id,
        obu:
          row[this.getColumnIndex("originatedHierarchy")].data != null
            ? row[this.getColumnIndex("originatedHierarchy")].data[0]?.id
            : null,
        baselLevel: {
          levelOne: row[this.getColumnIndex("baselLevel")].data.data?.parentId,
          levelTwo: row[this.getColumnIndex("baselLevel")].data?.id,
        },
        description: row[this.getColumnIndex("description")].data,
        riskLevel:
          row[this.getColumnIndex("riskUser")].data != null
            ? row[this.getColumnIndex("riskUser")].data.id
            : null,
        responsibleForReview:
          row[this.getColumnIndex("responsibleForReview")].data != null
            ? row[this.getColumnIndex("responsibleForReview")].data
            : null,
        reasonForNewRiskLevel:
          row[this.getColumnIndex("riskUser")].data != null
            ? row[this.getColumnIndex("riskUserJustification")].data
            : null,
        status: row[this.getColumnIndex("statusUser")].data?.id,
        accountingDate: row[this.getColumnIndex("accountingDate")].data,
        managerialImpact: row[this.getColumnIndex("managerialImpact")].data,
        AccountingAccount: row[this.getColumnIndex("accountingAccounts")].data,
      };

      var tempImpactedHierarchy = [];

      row[this.getColumnIndex("impactedHierarchy")].data?.forEach((area) => {
        tempImpactedHierarchy.push(area.id);
      });

      payload["ibu"] = tempImpactedHierarchy;

      var tempProduct = [];

      row[this.getColumnIndex("product")].data?.forEach((product) => {
        tempProduct.push(product.id);
      });

      payload["product"] = tempProduct;

      var tempSystem = [];

      row[this.getColumnIndex("system")].data?.forEach((system) => {
        tempSystem.push(system.id);
      });

      payload["system"] = tempSystem;

      var tempHashtag = [];

      row[this.getColumnIndex("hashtag")].data?.forEach((hashtag) => {
        if (hashtag.id == null) {
          tempHashtag.push({ suggestion: hashtag.label });
        } else {
          tempHashtag.push({ id: hashtag.id });
        }
      });

      payload["hashtag"] = tempHashtag;

      if(row[this.getColumnIndex("statusUser")].data?.id == 4) {
        payload["reviewedBy"] = row[this.getColumnIndex("responsibleForReview")].data;
      }

      return payload;
    },
    getColumnStyle(col) {
      return {
        width: `${this.columnMap[col].width}px`,
        "max-width": `${this.columnMap[col].width}px`,
        "min-width": `${this.columnMap[col].width}px`,
      };
    },
    getColumnIndex(col) {
      return Object.keys(this.columnMap).indexOf(col);
    },
    pasteCells(event, isEvent = true) {
      {
        let pastedData;

        if (isEvent) {
          let clipboardData = event.clipboardData || window.clipboardData;
          pastedData = clipboardData.getData("Text");
        } else {
          pastedData = event;
        }

        let rows = pastedData.trim().split("\n");
        let xOffset = this.selectedCell.colStart;
        let yOffset = this.selectedCell.rowStart;
        for (let i = yOffset; i < rows.length + yOffset; i++) {
          let cells = rows[i - yOffset].split("\t");
          for (let j = xOffset; j < cells.length + xOffset; j++) {
            cells[j - xOffset] = cells[j - xOffset].trim();
            this.setCellData(i, j, null);
            switch (this.columnMap[Object.keys(this.columnMap)[j]].type) {
              case "date": {
                if (cells[j - xOffset] == "") {
                  break;
                }
                this.setCellData(i, j, Util.parseDate(cells[j - xOffset]));
                break;
              }
              case "float": {
                if (cells[j - xOffset] == "") {
                  break;
                }
                this.setCellData(i, j, parseFloat(cells[j - xOffset]));
                break;
              }
              case "user": {
                const email = cells[j - xOffset].toLowerCase();
                if (email === "") {
                  break;
                }

                this.queueRequest(i, j, email);

                break;
              }

              case "text": {
                if (cells[j - xOffset] == "") {
                  break;
                }
                this.setCellData(i, j, cells[j - xOffset]);
                break;
              }
              case "autoComplete": {
                if (cells[j - xOffset] == "") {
                  break;
                }
                if (Object.keys(this.columnMap)[j] == "hashtag") {
                  let hashtagsToSearch = cells[j - xOffset].split(";");
                  let data = [];
                  for (let i = 0; i < hashtagsToSearch.length; i++) {
                    data.push(this.searchHashtagsExact(hashtagsToSearch[i].toLowerCase()));
                  }
                  this.setCellData(i, j, data);
                }
                break;
              }
              case "dropdown": {
                if (cells[j - xOffset] == "") {
                  break;
                }
                if (Object.keys(this.columnMap)[j] == "baselLevel") {
                  this.setCellData(
                    i,
                    j,
                    this.searchBaselLevel(cells[j - xOffset].toLowerCase())
                  );
                }
                if (Object.keys(this.columnMap)[j] == "riskFactor") {
                  this.setCellData(
                    i,
                    j,
                    this.searchRiskFactor(cells[j - xOffset].toLowerCase())
                  );
                }
                if (Object.keys(this.columnMap)[j] == "company") {
                  this.setCellData(
                    i,
                    j,
                    this.searchCompany(cells[j - xOffset].toLowerCase())
                  );
                }
                if (Object.keys(this.columnMap)[j] == "capability") {
                  this.setCellData(
                    i,
                    j,
                    this.searchCapability(cells[j - xOffset].toLowerCase())
                  );
                }
                if (Object.keys(this.columnMap)[j] == "ope01") {
                  this.setCellData(i, j, this.searchOpe01(cells[j - xOffset].toLowerCase()));
                }
                if (Object.keys(this.columnMap)[j] == "ope02") {
                  this.setCellData(i, j, this.searchOpe02(cells[j - xOffset].toLowerCase()));
                }
                if (Object.keys(this.columnMap)[j] == "rep01") {
                  this.setCellData(i, j, this.searchRep01(cells[j - xOffset].toLowerCase()));
                }
                if (Object.keys(this.columnMap)[j] == "rep02") {
                  this.setCellData(i, j, this.searchRep02(cells[j - xOffset].toLowerCase()));
                }
                if (Object.keys(this.columnMap)[j] == "reg01") {
                  this.setCellData(i, j, this.searchReg01(cells[j - xOffset].toLowerCase()));
                }
                if (Object.keys(this.columnMap)[j] == "fin01") {
                  this.setCellData(i, j, this.searchFin01(cells[j - xOffset].toLowerCase()));
                }
                if (Object.keys(this.columnMap)[j] == "fin02") {
                  this.setCellData(i, j, this.searchFin02(cells[j - xOffset].toLowerCase()));
                }
                if (Object.keys(this.columnMap)[j] == "fin03_currency") {
                  this.setCellData(i, j, this.searchFin03Currency(cells[j - xOffset].toLowerCase()));
                }
                if (Object.keys(this.columnMap)[j] == "fin04") {
                  this.setCellData(i, j, this.searchFin04(cells[j - xOffset].toLowerCase()));
                }
                if (Object.keys(this.columnMap)[j] == "statusUser") {
                  this.setCellData(i, j, this.searchStatus(cells[j - xOffset].toLowerCase()));
                }
                if (Object.keys(this.columnMap)[j] == "riskUser") {
                  this.setCellData(i, j, this.searchRiskUser(cells[j - xOffset].toLowerCase())
                  );
                }
                break;
              }
              case "multiselect": {
                if (cells[j - xOffset] == "") {
                  break;
                }
                if (Object.keys(this.columnMap)[j] == "system") {
                  let systemsToSearch = cells[j - xOffset].split(";");
                  let data = [];
                  for (let k = 0; k < systemsToSearch.length; k++) {
                    let tempSystem = this.searchSystem(systemsToSearch[k].toLowerCase())
                    if (tempSystem != null) {
                      data.push(tempSystem);
                    }
                    else {
                      document
                        .getElementsByClassName("cell-" + i + "-" + j)[0]
                        .classList.add("warning-batch-incident-insert");

                      this.warnings.push({
                        id: i.toString() + j.toString(),
                        value: `(aviso) Linha: ${i + 1}, Coluna: Sistema - O Sistema '${systemsToSearch[k]}' não foi encontrado`,
                      });
                    }
                  }
                  this.setCellData(i, j, data);
                }
                if (Object.keys(this.columnMap)[j] == "product") {
                  let productsToSearch = cells[j - xOffset].split(";");
                  let data = [];
                  for (let k = 0; k < productsToSearch.length; k++) {
                    let tempProduct = this.searchProduct(productsToSearch[k].toLowerCase())
                    if (tempProduct != null) {
                      data.push(tempProduct);
                    }
                    else {
                      document
                        .getElementsByClassName("cell-" + i + "-" + j)[0]
                        .classList.add("warning-batch-incident-insert");

                      this.warnings.push({
                        id: i.toString() + j.toString(),
                        value: `(aviso) Linha ${i + 1}, Coluna: Produto - O produto '${productsToSearch[k]}' não foi encontrado.`,
                      });
                    }
                  }
                  this.setCellData(i, j, data);
                }
                break;
              }
              case "dialog": {
                if (cells[j - xOffset] == "") {
                  break;
                }
                if (Object.keys(this.columnMap)[j] == "originatedHierarchy") {
                  let data = [];
                  let chips = [];
                  let result = this.searchHierarchy(cells[j - xOffset].toLowerCase());
                  if (result != null) {
                    for (let i = 0; i < result.data.length; i++) {
                      result.data[i].type = "originated";
                      data.push(result.data[i]);
                      chips.push(result.chips[i]);
                    }
                  }
                  if (data.length > 0 && chips.length > 0) {
                    this.setCellData(i, j, data, chips);
                  }
                }
                if (Object.keys(this.columnMap)[j] == "impactedHierarchy") {
                  let hierarchyToSearch = cells[j - xOffset].split(";");
                  let data = [];
                  let chips = [];
                  for (let i = 0; i < hierarchyToSearch.length; i++) {
                    let result = this.searchHierarchy(hierarchyToSearch[i].toLowerCase());
                    if (result != null) {
                      for (let j = 0; j < result.data.length; j++) {
                        result.data[j].type = "impacted";
                        data.push(result.data[j]);
                        chips.push(result.chips[j]);
                      }
                    }
                  }
                  if (data.length > 0 && chips.length > 0) {
                    this.setCellData(i, j, data, chips);
                  }
                }
                break;
              }
            }
          }
        }

        if (this.warnings.length > 0) {
          this.$toast.add({
            severity: "warn",
            summary: "Existem avisos no console!",
            life: 1500,
          });
        }
      }
    },
    setCellData(row, col, data, chips) {
      if (!this.fullData[row] || !this.fullData[row].data[col]) {
        return;
      }

      if (this.fullData[row].data[col]?.blocked) {
        return;
      }

      this.fullData[row].data[col].data = data;
      this.fullData[row].data[col].chips = chips;
    },
    copyCells(event) {
      let tmp = [];
      Array.from(document.querySelectorAll(".selected")).forEach((el) => {
        el.classList.add("copy");
      });
      for (
        let i = this.selectedCell.rowStart;
        i <= this.selectedCell.rowEnd;
        i++
      ) {
        let tmp1 = [];
        for (
          let j = this.selectedCell.colStart;
          j <= this.selectedCell.colEnd;
          j++
        ) {
          let value = "";
          switch (this.columnMap[Object.keys(this.columnMap)[j]].type) {
            case "date":
              if (this.fullData[i].data[j].data != null) {
                value =
                  this.fullData[i].data[j].data.getDate() +
                  "/" +
                  (this.fullData[i].data[j].data.getMonth() + 1) +
                  "/" +
                  this.fullData[i].data[j].data.getFullYear();
              }
              break;
            case "text":
              if (this.fullData[i].data[j].data != null) {
                value = this.fullData[i].data[j].data;
              }
              break;
            case "autoComplete":
              break;
            case "dropdown":
              break;
            case "multiselect":
              break;
          }
          tmp1.push(value);
        }
        tmp.push(tmp1.join("	"));
      }
      event.clipboardData.setData("text/plain", tmp.join("\n"));
      event.preventDefault();
    },
    keyboardHandler(event) {
      if (event.defaultPrevented) {
        return; // Do nothing if the event was already processed
      }
      let key = event.key;
      if (event.shiftKey) {
        key = "Shift+" + key;
      }
      if (event.ctrlKey) {
        key = "Ctrl+" + key;
      }
      switch (key) {
        case "[":
          break;
        case "F2":
          this.editCell(this.selectedCell.rowStart, this.selectedCell.colStart);
          break;
        case "Delete":
          this.eraseCells();
          break;
        // case "Backspace":
        //   this.eraseCells();
        //   break;
        case "Enter":
          this.moveCellDown();
          this.isEditing = false;
          break;
        case "ArrowDown":
          this.moveCellDown();
          break;
        case "Shift+Enter":
          this.moveCellUp();
          break;
        case "ArrowUp":
          this.moveCellUp();
          break;
        case "Shift+Tab":
          this.moveCellLeft();
          break;
        case "ArrowLeft":
          if (this.isEditing) {
            return;
          }
          this.moveCellLeft();
          break;
        case "Tab":
          this.moveCellRight();
          break;
        case "ArrowRight":
          if (this.isEditing) {
            return;
          }
          this.moveCellRight();
          break;
        default:
          return; // Quit when this doesn't handle the key event.
      }

      // Cancel the default action to avoid it being handled twice
      //   event.preventDefault();
    },
    eraseCells() {
      this.allValidated = false;
      for (
        let i = this.selectedCell.rowStart;
        i <= this.selectedCell.rowEnd;
        i++
      ) {
        for (
          let j = this.selectedCell.colStart;
          j <= this.selectedCell.colEnd;
          j++
        ) {
          this.fullData[i].data[j].data = null;
          document
            .getElementsByClassName("cell-" + i + "-" + j)[0]
            .classList.remove("validated-batch-insert");
        }
      }
    },
    moveCellLeft() {
      // document.getElementById("hidden").focus();
      if (
        this.selectedCell.rowStart != null &&
        this.selectedCell.colStart != null
      ) {
        if (this.selectedCell.colStart == 0) {
          return;
        }
        this.selectCell(
          this.selectedCell.rowStart,
          this.selectedCell.colStart - 1
        );
      }
    },
    moveCellRight() {
      // document.getElementById("hidden").focus();
      if (
        this.selectedCell.rowStart != null &&
        this.selectedCell.colStart != null
      ) {
        if (this.selectedCell.colStart == this.fullData[0].data.length - 1) {
          return;
        }
        this.selectCell(
          this.selectedCell.rowStart,
          this.selectedCell.colStart + 1
        );
      }
    },
    moveCellUp() {
      // document.getElementById("hidden").focus();
      if (
        this.selectedCell.rowStart != null &&
        this.selectedCell.colStart != null
      ) {
        if (this.selectedCell.rowStart == 0) {
          return;
        }
        this.selectCell(
          this.selectedCell.rowStart - 1,
          this.selectedCell.colStart
        );
      }
    },
    moveCellDown() {
      // document.getElementById("hidden").focus();
      if (
        this.selectedCell.rowStart != null &&
        this.selectedCell.colStart != null
      ) {
        if (this.selectedCell.rowStart == this.fullData.length - 1) {
          return;
        }
        this.selectCell(
          this.selectedCell.rowStart + 1,
          this.selectedCell.colStart
        );
      }
    },
    // printFullData() {
    //   console.log(JSON.stringify(this.fullData));
    // },
    clickSelector(event, row, col) {
      this.clicks++;
      this.selectCell(row, col, true);

      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.clicks = 0;
        }, this.delay);
        // this.pauseEvent(event);
      } else {
        clearTimeout(this.timer);
        this.clicks = 0;
        if (
          row == this.lastClickedCell.row &&
          col == this.lastClickedCell.col
        ) {
          this.editCell(row, col);
        }
      }
      this.lastClickedCell.row = row;
      this.lastClickedCell.col = col;
    },
    selectCell(row, col, cross) {
      this.isEditing = false;
      Array.from(document.querySelectorAll(".selected")).forEach((el) =>
        el.classList.remove("selected")
      );
      Array.from(document.querySelectorAll(".copy")).forEach((el) =>
        el.classList.remove("copy")
      );
      Array.from(document.querySelectorAll(".selected-editting-batch-insert")).forEach(
        (el) => el.classList.remove("selected-editting-batch-insert")
      );
      Array.from(document.querySelectorAll(".drag-handle-batch-insert")).forEach((el) =>
        el.classList.remove("drag-handle-batch-insert")
      );
      this.selectedCell = {
        rowStart: parseInt(row),
        colStart: parseInt(col),
        rowEnd: parseInt(row),
        colEnd: parseInt(col),
      };

      document
        .getElementsByClassName("cell-" + row + "-" + col)[0]
        .parentElement.classList.add("selected");

      if (cross && !this.fullData[row].data[col].blocked) {
        document
          .getElementsByClassName("drag-handle-batch-insert-" + row + "-" + col)[0]
          .classList.add("drag-handle-batch-insert");
      }
    },
    selectCol(col) {
      if (this.wasResizing) {
        return;
      }
      Array.from(document.querySelectorAll(".selected")).forEach((el) =>
        el.classList.remove("selected")
      );
      Array.from(document.querySelectorAll(".copy")).forEach((el) =>
        el.classList.remove("copy")
      );
      Array.from(document.querySelectorAll(".selected-editting-batch-insert")).forEach(
        (el) => el.classList.remove("selected-editting-batch-insert")
      );
      Array.from(document.querySelectorAll(".drag-handle-batch-insert")).forEach((el) =>
        el.classList.remove("drag-handle-batch-insert")
      );
      this.selectedCell = {
        rowStart: parseInt(0),
        colStart: parseInt(col),
        rowEnd: parseInt(this.fullData.length - 1),
        colEnd: parseInt(col),
      };

      for (let i = 0; i < this.fullData.length; i++) {
        document
          .getElementsByClassName("cell-" + i + "-" + col)[0]
          .parentElement.classList.add("selected");
      }
    },
    selectRow(row) {
      Array.from(document.querySelectorAll(".selected")).forEach((el) =>
        el.classList.remove("selected")
      );
      Array.from(document.querySelectorAll(".copy")).forEach((el) =>
        el.classList.remove("copy")
      );
      Array.from(document.querySelectorAll(".selected-editting-batch-insert")).forEach(
        (el) => el.classList.remove("selected-editting-batch-insert")
      );
      Array.from(document.querySelectorAll(".drag-handle-batch-insert")).forEach((el) =>
        el.classList.remove("drag-handle-batch-insert")
      );
      this.selectedCell = {
        rowStart: parseInt(row),
        colStart: parseInt(0),
        rowEnd: parseInt(row),
        colEnd: parseInt(this.fullData[0].data.length - 1),
      };

      for (let i = 0; i < this.fullData[0].data.length; i++) {
        document
          .getElementsByClassName("cell-" + row + "-" + i)[0]
          .parentElement.classList.add("selected");
      }
    },
    blockCell(row, col) {
      this.fullData[row].data[col].blocked = true;
      let posS = "-" + row + "-" + col;
      document
        .getElementsByClassName("cell" + posS)[0]
        .classList.add("blocked-batch-insert");
    },
    editCell(row, col) {
      if (this.fullData[row].data[col].blocked) {
        return;
      }
      this.allValidated = false;
      document
        .getElementsByClassName("cell-" + row + "-" + col)[0]
        .classList.remove("validated-batch-insert");
      this.isEditing = true;
      let posS = "-" + row + "-" + col;
      document
        .getElementsByClassName("cell" + posS)[0]
        .classList.add("selected-editting-batch-insert");
      switch (this.columnMap[Object.keys(this.columnMap)[col]].type) {
        case "date":
          setTimeout(() => {
            this.$refs["ref-input" + posS].$el.children[0].focus();
          }, 20);
          break;
        case "float":
          setTimeout(() => {
            this.$refs["ref-input" + posS].$el.children[0].focus();
          }, 20);
          break;
        case "text":
          setTimeout(() => {
            this.$refs["ref-input" + posS].$el.focus();
          }, 20);
          break;
        case "autoComplete":
          setTimeout(() => {
            this.$refs["ref-input" + posS].$el.children[0].children[
              this.$refs["ref-input" + posS].$el.children[0].children.length - 1
            ].children[0].focus();
          }, 20);
          break;
        case "user":
          setTimeout(() => {
            this.$refs["ref-input" + posS].$el.children[0].focus();
          }, 20);
          break;
        case "dropdown":
          setTimeout(() => {
            Util.simulate(this.$refs["ref-input" + posS].$el, "click");
          }, 20);
          break;
        case "multiselect":
          setTimeout(() => {
            Util.simulate(this.$refs["ref-input" + posS].$el, "click");
          }, 20);
          break;
        case "dialog":
          setTimeout(() => {
            if (Object.keys(this.columnMap)[col] == "originatedHierarchy") {
              this.openHierarchySingleDialog(row, col);
            }
            if (Object.keys(this.columnMap)[col] == "impactedHierarchy") {
              this.openHierarchyMultipleDialog(row, col);
            }
          }, 20);
          break;
      }
      Util.length;
    },
    openHierarchyMultipleDialog(row, col) {
      this.areaHierarchyValue = this.fullData[row].data[col].data;
      this.buttonPos = { row: row, col: col };
      this.hierarchyMultipleVisible = true;
    },
    openHierarchySingleDialog(row, col) {
      this.areaHierarchyValue = this.fullData[row].data[col].data;
      this.buttonPos = { row: row, col: col };
      this.hierarchySingleVisible = true;
    },
    saveHierarchy() {
      this.fullData[this.buttonPos.row].data[this.buttonPos.col].data =
        this.areaHierarchyValue;
      this.fullData[this.buttonPos.row].data[this.buttonPos.col].chips =
        this.getLabelAreas(this.areaHierarchyValue);
      this.areaHierarchyValue = null;
      this.buttonPos = null;
      this.hierarchyMultipleVisible = false;
      this.hierarchySingleVisible = false;
    },
    setObuAP(value) {
      this.areaHierarchyValue = value;
    },
    populateHierarchyArray() {
      function visitNodes(tree, path = []) {
        if (!tree) {
          return [];
        }

        path.push(tree.label);
        const info = {
          id: tree.id,
          label: tree.label,
          path: path.join(" -> "),
          selectable: tree.selectable,
        };

        const result = [info];

        if (tree.children && tree.children?.length > 0) {
          for (const children of tree.children) {
            result.push(...visitNodes(children, path.slice()));
          }
        }

        return result;
      }

      for (let i = 0; i < this.areaHierarchy?.length; i++) {
        this.hierarchyArray = this.hierarchyArray.concat(
          visitNodes(this.areaHierarchy[i])
        );
      }
    },
    getLabelAreas(hierarchy) {

      if (hierarchy != null) {
        const labelAreas = [];

        hierarchy.forEach((tempArea) => {
          labelAreas.push(
            this.hierarchyArray.find((object) => object.id === tempArea.id)
              ?.label
          );
        });
        return labelAreas;
      }
    },
    // searchHashtags(event) {
    //   setTimeout(() => {
    //     if (!event.query.trim()?.length) {
    //       this.filteredHashtags = this.hashtags;
    //     } else {
    //       let tempArray = [];
    //       tempArray.push({ label: "#" + event.query.toLowerCase(), id: null });

    //       this.hashtags.forEach((hashtag) => {
    //         if (
    //           hashtag.filter.some((filter) =>
    //             filter.includes(event.query.toLowerCase())
    //           )
    //         ) {
    //           tempArray.push(hashtag);
    //         }
    //       });
    //       this.filteredHashtags = tempArray;
    //     }
    //   }, 250);
    // },
    searchHashtags(event) {
      setTimeout(() => {
        if (!event.query.trim()?.length) {
          this.filteredHashtags = this.hashtags;
        } else {
          let tempArray = [];
          tempArray.push({
            label: "#" + event.query.toLowerCase().replace(/^#+/, "").replace(/\s+/g, "_"),
            id: null,
            suggestion: true
          });

          this.hashtags.forEach((hashtag) => {
            if (
              hashtag.inComponent &&
              hashtag.selectable &&
              hashtag.filter.keyword.some((filter) =>
                filter.includes(event.query.toLowerCase())
              )
            ) {
              tempArray.push(hashtag);
            }
          });
          this.filteredHashtags = tempArray;
        }
      }, 250);
    },
    searchHashtagsExact(input) {
      input = input.trim();
      if (!input.includes("#")) {
        input = "#" + input;
      }
      for (let i = 0; i < this.hashtags.length; i++) {
        if (this.hashtags[i].label == input) {
          input = input.trim();
          return this.hashtags[i];
        }
      }
      return { label: input, id: null };
    },
    searchBaselLevel(input) {
      input = input.trim();
      for (let i = 0; i < this.baselLevel.length; i++) {
        for (let j = 0; j < this.baselLevel[i].children.length; j++) {
          if (
            this.baselLevel[i].children[j].label.toLowerCase().includes(input)
          ) {
            return this.baselLevel[i].children[j];
          }
        }
      }
      return null;
    },
    searchRiskFactor(input) {
      input = input.trim();
      for (let i = 0; i < this.riskFactor.length; i++) {
        if (this.riskFactor[i].label.toLowerCase().includes(input)) {
          return this.riskFactor[i];
        }
      }
      return null;
    },
    searchSystem(input) {
      input = input.trim();
      for (let i = 0; i < this.system.length; i++) {
        if (this.system[i].label.toLowerCase().includes(input)) {
          return this.system[i];
        }
      }
      return null;
    },
    searchProduct(input) {
      input = input.trim();
      for (let i = 0; i < this.product.length; i++) {
        if (this.product[i].label.toLowerCase().includes(input)) {
          return this.product[i];
        }
      }
      return null;
    },
    searchCompany(input) {
      input = input.trim();
      for (let i = 0; i < this.company.length; i++) {
        for (let j = 0; j < this.company[i].children.length; j++) {
          if (this.company[i].children[j].label.toLowerCase().includes(input)) {
            return this.company[i].children[j];
          }
        }
      }
      return null;
    },
    searchCapability(input) {
      input = input.trim();
      for (let i = 0; i < this.capability.length; i++) {
        if (this.capability[i].label.toLowerCase().includes(input)) {
          return this.capability[i];
        }
      }
      return null;
    },
    searchOpe01(input) {
      input = input.trim();
      for (let i = 0; i < this.ope01.length; i++) {
        if (this.ope01[i].label.toLowerCase().includes(input)) {
          return this.ope01[i];
        }
      }
      return null;
    },
    searchOpe02(input) {
      input = input.trim();
      for (let i = 0; i < this.ope02.length; i++) {
        if (this.ope02[i].label.toLowerCase().includes(input)) {
          return this.ope02[i];
        }
      }
      return null;
    },
    searchReg01(input) {
      input = input.trim();
      for (let i = 0; i < this.reg01.length; i++) {
        if (this.reg01[i].label.toLowerCase().includes(input)) {
          return this.reg01[i];
        }
      }
      return null;
    },
    searchRep01(input) {
      input = input.trim();
      for (let i = 0; i < this.rep01.length; i++) {
        if (this.rep01[i].label.toLowerCase().includes(input)) {
          return this.rep01[i];
        }
      }
      return null;
    },
    searchRep02(input) {
      input = input.trim();
      for (let i = 0; i < this.rep02.length; i++) {
        if (this.rep02[i].label.toLowerCase().includes(input)) {
          return this.rep02[i];
        }
      }
      return null;
    },
    searchFin01(input) {
      input = input.trim();
      for (let i = 0; i < this.fin01.length; i++) {
        if (this.fin01[i].label.toLowerCase().includes(input)) {
          return this.fin01[i];
        }
      }
      return null;
    },
    searchFin02(input) {
      input = input.trim();
      for (let i = 0; i < this.fin02.length; i++) {
        if (this.fin02[i].label.toLowerCase().includes(input)) {
          return this.fin02[i];
        }
      }
      return null;
    },
    searchFin03Currency(input) {
      input = input.trim();
      for (let i = 0; i < this.fin03_currency.length; i++) {
        if (this.fin03_currency[i].label.toLowerCase().includes(input)) {
          return this.fin03_currency[i];
        }
      }
      return null;
    },
    searchFin04(input) {
      input = input.trim();
      for (let i = 0; i < this.fin04.length; i++) {
        if (this.fin04[i].label.toLowerCase().includes(input)) {
          return this.fin04[i];
        }
      }
      return null;
    },
    searchStatus(input) {
      input = input.trim();
      for (let i = 0; i < this.status.length; i++) {
        if (this.status[i].label.toLowerCase().includes(input)) {
          return this.status[i];
        }
      }
      return null;
    },
    searchRiskUser(input) {
      input = input.trim();
      for (let i = 0; i < this.riskLevel.length; i++) {
        if (this.riskLevel[i].label.toLowerCase().includes(input)) {
          return this.riskLevel[i];
        }
      }
      return null;
    },
    searchHierarchy(input) {
      input = input.trim();
      for (let i = 0; i < this.hierarchyArray.length; i++) {
        if (
          this.hierarchyArray[i].label.toLowerCase().includes(input) &&
          this.hierarchyArray[i].selectable
        ) {
          return {
            data: [
              {
                id: this.hierarchyArray[i].id,
                label: this.hierarchyArray[i].label,
              },
            ],
            chips: [this.hierarchyArray[i].label],
          };
        }
      }
      return null;
    },
    searchUsers(event, filter = "users") {
      let tempArray = [];

      GraphService.searchUser(event.query, filter).then((response) => {
        response.forEach((user) => {
          if (user.mail != "") {
            tempArray.push(user.mail.toLowerCase());
          }
        });
        this.filteredUsers = tempArray;
      });
    },
    pauseEvent(e) {
      if (e.stopPropagation) e.stopPropagation();
      if (e.preventDefault) e.preventDefault();
      e.cancelBubble = true;
      e.returnValue = false;
      return false;
    },
    mouseMove(event) {
      if (this.startDragColResize != null) {
        this.columnMap[
          Object.keys(this.columnMap)[this.startDragColResize.col]
        ].width =
          this.startDragColResize.startWidth +
          (event.screenX - this.startDragColResize.posStartX);
        return;
      }
      if (this.startDragSelectable != null) {
        const startRowIndex = this.startDragSelectable.row;
        const startCellIndex = this.startDragSelectable.col;
        let dragCell = this.getSelectable(event);
        if (dragCell == null) {
          return;
        }
        let tmp = Array.from(dragCell.classList).filter((item) => {
          return item.includes("cell");
        });
        tmp = tmp[0].split("-");
        let endRowIndex = tmp[1];
        let endCellIndex = tmp[2];

        Array.from(document.querySelectorAll(".selected")).forEach((el) =>
          el.classList.remove("selected")
        );
        Array.from(document.querySelectorAll(".copy")).forEach((el) =>
          el.classList.remove("copy")
        );
        for (
          let i = Math.min(startRowIndex, endRowIndex);
          i <= Math.max(startRowIndex, endRowIndex);
          i++
        ) {
          for (
            let j = Math.min(startCellIndex, endCellIndex);
            j <= Math.max(startCellIndex, endCellIndex);
            j++
          ) {
            document
              .getElementsByClassName("cell-" + i + "-" + j)[0]
              .parentElement.classList.add("selected");
          }
        }
      }
      if (this.startDragCopyCross != null) {
        const startRowIndex = this.startDragCopyCross.row;
        const startCellIndex = this.startDragCopyCross.col;
        let dragCell = this.getSelectable(event);
        if (dragCell == null) {
          return;
        }
        let tmp = Array.from(dragCell.classList).filter((item) => {
          return item.includes("cell");
        });
        tmp = tmp[0].split("-");
        let endRowIndex = tmp[1];
        let endCellIndex = tmp[2];

        Array.from(document.querySelectorAll(".blue")).forEach((el) =>
          el.classList.remove("blue")
        );
        if (this.copyH) {
          for (
            let i = Math.min(startRowIndex, endRowIndex);
            i <= Math.max(startRowIndex, endRowIndex);
            i++
          ) {
            for (
              let j = Math.min(startCellIndex, endCellIndex);
              j <= Math.max(startCellIndex, endCellIndex);
              j++
            ) {
              document
                .getElementsByClassName("cell-" + i + "-" + j)[0]
                .parentElement.classList.add("blue");
            }
          }
        } else {
          let j = startCellIndex;
          for (
            let i = Math.min(startRowIndex, endRowIndex);
            i <= Math.max(startRowIndex, endRowIndex);
            i++
          ) {
            document
              .getElementsByClassName("cell-" + i + "-" + j)[0]
              .parentElement.classList.add("blue");
          }
        }
      }
    },
    mouseDown(event) {
      if (event.target.classList.contains("selectable")) {
        this.pauseEvent(event);
        let tmp = Array.from(event.target.classList).filter((item) => {
          return item.includes("cell");
        });
        tmp = tmp[0].split("-");
        let row = tmp[1];
        let col = tmp[2];
        this.startDragSelectable = { row: row, col: col };
        this.selectCell(row, col, false);
      }
      if (event.target.classList.contains("drag-handle-batch-insert")) {
        this.pauseEvent(event);
        let tmp = Array.from(event.target.classList).filter((item) => {
          return item.includes("drag-handle-batch-insert-");
        });
        tmp = tmp[0].split("-");
        let row = tmp[2];
        let col = tmp[3];
        this.startDragCopyCross = { row: row, col: col };
      }
      if (event.target.classList.contains("drag-col-handle")) {
        this.pauseEvent(event);
        let tmp = Array.from(event.target.classList).filter((item) => {
          return item.includes("drag-col-handle-");
        });
        tmp = tmp[0].split("-");
        let col = tmp[3];
        this.startDragColResize = {
          col: col,
          posStartX: event.screenX,
          startWidth: this.columnMap[Object.keys(this.columnMap)[col]].width,
        };
        this.wasResizing = true;
      }
      // document.getElementById("hidden").focus();
    },
    mouseUp(event) {
      if (this.startDragColResize != null) {
        this.startDragColResize = null;
        setTimeout(() => {
          this.wasResizing = false;
        }, 20);
        this.pauseEvent(event);
        return;
      }
      if (this.startDragSelectable != null) {
        this.startDragSelectable = null;
        let tmp = Array.from(event.target.classList).filter((item) => {
          return item.includes("cell");
        });
        tmp = tmp[0].split("-");
        // let endRowIndex = tmp[1];
        // let endCellIndex = tmp[2];
        // const valueToCopy =
        //   this.fullData[startRowIndex].data[startCellIndex].data;
        // const chipsToCopy =
        //   this.fullData[startRowIndex].data[startCellIndex].chips;
        this.selectedCell.rowEnd = parseInt(tmp[1]);
        this.selectedCell.colEnd = parseInt(tmp[2]);
        return;
      }
      if (this.startDragCopyCross != null) {
        const startRowIndex = this.startDragCopyCross.row;
        const startCellIndex = this.startDragCopyCross.col;

        let tmp = Array.from(event.target.classList).filter((item) => {
          return item.includes("cell");
        });
        tmp = tmp[0].split("-");
        let endRowIndex = tmp[1];
        let endCellIndex = tmp[2];
        const valueToCopy =
          this.fullData[startRowIndex].data[startCellIndex].data;
        const chipsToCopy =
          this.fullData[startRowIndex].data[startCellIndex].chips;

        if (this.copyH) {
          for (
            let i = Math.min(startRowIndex, endRowIndex);
            i <= Math.max(startRowIndex, endRowIndex);
            i++
          ) {
            for (
              let j = Math.min(startCellIndex, endCellIndex);
              j <= Math.max(startCellIndex, endCellIndex);
              j++
            ) {
              this.fullData[i].data[j].data = valueToCopy;
              this.fullData[i].data[j].chips = chipsToCopy;
            }
          }
        } else {
          let j = startCellIndex;

          for (
            let i = Math.min(startRowIndex, endRowIndex);
            i <= Math.max(startRowIndex, endRowIndex);
            i++
          ) {
            this.fullData[i].data[j].data = valueToCopy;
            this.fullData[i].data[j].chips = chipsToCopy;
          }
        }

        Array.from(document.querySelectorAll(".blue")).forEach((el) =>
          el.classList.remove("blue")
        );
      }
      this.startDragCopyCross = null;
    },
    getSelectable(event) {
      let out = null;
      if (event.target.classList.contains("selectable")) {
        out = event.target;
      }
      return out;
    },
    copyId(index) {
      if (this.fullData[index].data[this.getColumnIndex("id")].data != null) {
        let id = this.fullData[index].data[this.getColumnIndex("id")].data;
        Util.copyToClipboard(id, id);

        this.$toast.add({
          severity: "info",
          summary: "Id copiado com sucesso",
          life: 1000,
        });
      }
    },
    navigateToIncident(index) {
      if (this.fullData[index].data[this.getColumnIndex("id")].data != null) {
        let id = this.fullData[index].data[this.getColumnIndex("id")].data;

        let baseUrl = window.location.origin + "/#/";

        window.open(baseUrl + "incident/search?id=" + id, "_blank");
      }
    },
    dataURLToBlob(dataURL) {
      const byteString = atob(dataURL.split(",")[1]);
      const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    downloadExcelTemplate() {
      import(
        "!!url-loader!../assets/static/Template - Entuba de Incidentes (STORM).xlsm"
      ).then((dataUrl) => {
        const blob = this.dataURLToBlob(dataUrl.default);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Template - Entuba de Incidentes (STORM).xlsm";
        link.click();
      });
    },
    async pasteEntubaTableIncidentBatch() {
      await this.mountTable();

      Util.loadRequest()
      this.selectCell(0, 3);

      await new Promise(resolve => setTimeout(resolve, 0));

      try {
        const text = await Util.getClipboard();

        this.pasteCells(text, false);

        this.$toast.add({
          severity: "success",
          summary: "Colado com sucesso!",
          life: 1500,
        });
      } catch (error) {
        console.error("Erro ao pegar o conteúdo da área de transferência", error);
      } finally {
        Util.loadedRequest()
      }
    },
  },
};
</script>

<style>
#editableTableBatchInsert table {
  width: 100%;
}

#editableTableBatchInsert th,
#editableTableBatchInsert td {
  padding: 0px;
  text-align: left;
  position: relative;
  height: 1px;
}

#editableTableBatchInsert th {
  background-color: #f2f2f2;
}

#editableTableBatchInsert table {
  white-space: nowrap;
}

.blue {
  border: 2px solid aqua;
  /* border-color: aqua; */
}

#editableTableBatchInsert {
  height: 1px;
}


.p-autocomplete-token:has(> div.hashtag-suggestion) {
  background-color: #fffdb3 !important;
}

.p-autocomplete-item:has(> div.hashtag-suggestion) {
  background-color: #fffdb3 !important;
}

.custom-input-batch-incident-insert {
  height: 100%;
  width: 100%;
}

.custom-input-batch-incident-insert>.p-dropdown>.p-dropdown-label {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}

.custom-input-batch-incident-insert>.p-dropdown {
  align-items: center;
  border: none !important;
  box-shadow: none !important;
  display: flex;
  height: 100%;
  width: 100%;
}

.custom-input-batch-incident-insert>.p-autocomplete>.p-autocomplete-multiple-container {
  border: none !important;
  box-shadow: none !important;
  height: 100%;
  width: 100%;
}

.custom-input-batch-incident-insert>.p-inputtext {
  border: none !important;
  padding: 0px !important;
  box-shadow: none !important;
  text-align: center !important;
  height: 100%;
  width: 100%;
}

.custom-input-batch-incident-insert>.p-multiselect {
  border: none !important;
  display: flex;
  align-items: center;
  box-shadow: none !important;
  height: 100%;
  width: 100%;
}

.custom-input-batch-incident-insert>.p-autocomplete {
  height: 100%;
  width: 100%;
}

.custom-input-batch-incident-insert>.p-autocomplete>.p-autocomplete-input {
  border: none !important;
  padding: 0px !important;
  display: flex;
  align-items: center;
  box-shadow: none !important;
  height: 100%;
  width: 100%;
  text-align: center !important;
}

.custom-input-batch-incident-insert>.p-autocomplete>.p-autocomplete-multiple-container>.p-autocomplete-token {
  padding: 4px 8px;
  margin-bottom: 1px;
}

.custom-input-batch-incident-insert>.p-calendar {
  height: 100%;
  width: 100%;
}

.custom-input-batch-incident-insert>.p-calendar>.p-inputtext {
  border: none !important;
  box-shadow: none !important;
  height: 100%;
  width: 100%;
}

.custom-input-batch-incident-insert>.p-inputnumber {
  height: 100%;
  width: 100%;
}

.custom-input-batch-incident-insert>.p-inputnumber>.p-inputtext {
  border: none !important;
  padding: 0px !important;
  box-shadow: none !important;
  text-align: center !important;
  height: 100%;
  width: 100%;
}

.cell-default-batch-insert {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.scroll-custom-batch-incident-insert>.p-scrollpanel-bar {
  background-color: #195ab4;
}

.custom-input-batch-incident-insert>textarea {
  resize: none;
}

.td-custom-batch-insert {
  /* width: 200px;
  max-width: 200px;
  min-width: 200px; */
}

.blocked-batch-insert {
  background-color: rgba(212, 212, 212, 0.308) !important;
}

.validated-batch-insert {
  background-color: rgba(134, 255, 130, 0.308) !important;
}

.errorValidated-batch-insert {
  background-color: rgba(255, 130, 130, 0.308) !important;
}

.selectable {
  height: 100%;
  width: 100%;
  background-color: transparent;
  position: absolute;
  z-index: 100;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: rgb(233, 233, 233);
  border-right-color: rgb(233, 233, 233);
  border-bottom-color: rgb(233, 233, 233);
}

.drag-handle-batch-insert {
  width: 5px;
  height: 5px;
  background: #000;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: crosshair;
  z-index: 102;
}

.drag-col-handle {
  width: 2px;
  height: 100%;
  background: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: col-resize;
  z-index: 102;
}

.selected {
  background-color: transparent;
  outline: 2px solid rgb(0, 122, 37);
  z-index: 101;
}

.selected.copy {
  background-color: transparent;
  outline: 2px dashed rgb(0, 122, 37);
  z-index: 101;
}

.selected-editting-batch-insert {
  pointer-events: none;
}

.row-index-batch-insert {
  /* resize: none !important;
  position: sticky;
  left: 0; */
  z-index: 10;
  padding: 5px;
  background-color: #f2f2f2;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: grey;
  border-right-color: grey;
  border-bottom-color: grey;
}

.table-corner-batch-insert {
  z-index: 10;
  background-color: #f2f2f2;
  border-top-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: grey;
  border-right-color: grey;
  border-bottom-color: grey;
}

.col-index-batch-insert {
  background-color: #f2f2f2;
  border-top-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: grey;
  border-right-color: grey;
  border-bottom-color: grey;
  white-space: normal;
}

.customHierarchyDialogBatchInsert .p-dialog-header {
  display: none;
}

.customHierarchyDialogBatchInsert .p-dialog-content {
  border-radius: 8px;
}

/* Track */
.scrollDiv::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scrollDiv::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
}

/* Handle */
.scrollDiv::-webkit-scrollbar-thumb {
  background: var(--color_brand_medium);
  border-radius: 100px;
}

.customInline {
  justify-content: start;
}

.customInstructionsDialogBatchInsert .p-dialog-content {
  background: transparent;
  padding: 0px;
}

.customInstructionsDialogBatchInsert .p-dialog-header {
  display: none;
}

.stickyHead-batch-incident-insert {
  position: sticky;
  top: 0;
  z-index: 1001;
}

.warning-batch-incident-insert {
  background: #fbb4453d;
}
</style>